.privacy-info {
    &__inner{
        @include tabpc{
            min-width: 1024px;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 20px 100px;
        }
        
    }
    &__ttl{
        font-size: 1.6rem;
        &:before{
            content: "◇";
        }
    }
    &__txt{
        margin: 0 0 1.5em;
    }
    &__items{
        padding-left: 20px;
        margin: 0 0 1.5em;
        list-style: decimal
        
    }
    &__item{
        line-height: 28px;
    }
    &__numbers{
        list-style: inside;
    }
    // ------ common
    .u-inline {
        display: inline-block;
    }
    .u-tar {
        text-align: right;
    }
}