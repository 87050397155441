// =========================================================
// VALUES
// =========================================================

// Base Color
$default: #333;
$black: #000;
$black02: #141414;
$white: #fff;
$yellow: #fecb0a;
$yellow_shadow: #c79f08;
$green: #0aad8a;
$dark_green: #00624d; //rgba(0,98,77,.7)
$gray:   #f5f6f8;
$gray02:   #e6e6e6;
$dark_gray: #c6c9d3;
$border_gray: #c3c9d9;
$alert_red :#c40000;

$red:   #e30b17;
$light_gray: #f7f7f7;
$dark_gray: #999;
$default_link_color: #333;
$default_link_color_hover: #e60000;

$main_color: #0079C4;
$sub_color: #18358E;
$background_color: #DFEAF1;
$btn-color-orange: #EF7B00;
$font-color-orange: #0079C4;
$opacity : 0.8;
// Breakpoint
// 設定した値+1がmin-widthに入る形
$sp: 768;
$tab: 960;
$pc: 1360;
$inner: 1024;
$compWidth: 750;

// Inner point
$sp_pd: 20px;
$pc_pd: 20px;

// Reboot
$font-size: 62.5%;
// $font-size-sp: 1.4rem;
// $font-size-pc: 1.6rem;
// $font-family: YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "MS Pゴシック", sans-serif;
$font-family: "Helvetica Neue", "Arial", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "メイリオ", "Meiryo", "MS PGothic", sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');
$font-famiily-jost: 'Jost', sans-serif;