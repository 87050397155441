/*====================================================================================================
//////////////////////////////////////////////////////////////////////////////////////////////////////

version: 1.0
created: 2016/10/03
update : -

//////////////////////////////////////////////////////////////////////////////////////////////////////
====================================================================================================*/

//.index .mod-box {
//  .mod-textHeading {
//    margin-bottom: 20px;
//
//    &::before {
//      top: 10px;
//    }
//  }
//
//  .summary {
//    margin-bottom: 15px;
//  }
//
//  .mod-list {
//    margin-right: 0;
//    padding: 12px;
//    background: #ffffff;
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .index .mod-box .mod-textHeading {
//    margin-bottom: 15px;
//
//    a::before {
//      top: 2px;
//    }
//  }
//}
//
//.detail {
//  .lead {
//    margin: 0 auto;
//    padding: 40px 0;
//    width: 984px;
//
//    .mod-introductionBox + p {
//      margin: 40px 0 0;
//      font-size: 2.0rem;
//      text-align: center;
//    }
//  }
//
//  .serviceSection01, .serviceSection02 {
//    position: relative;
//    margin: 0 auto;
//    padding: 80px 0;
//    width: 984px;
//    z-index: 0;
//  }
//
//  .serviceSection01 > *:last-child, .serviceSection02 > *:last-child //{
//    margin-bottom: 0 !important;
//  }
//
//  .serviceSection01::before, .serviceSection02::before {
//    position: absolute;
//    top: 0;
//    right: auto;
//    bottom: auto;
//    left: 50%;
//    content: "";
//    display: block;
//    margin: 0 0 0 -60px;
//    border-top: 4px solid #f5c51e;
//    width: 120px;
//    z-index: 1;
//  }
//
//  .serviceSection01 > .mod-textHeading, .serviceSection02 > .//mod-textHeading {
//    margin: 0 0 20px;
//    text-align: center;
//  }
//
//  .serviceSection01 {
//    &::before {
//      top: -4px;
//    }
//
//    &::after {
//      position: absolute;
//      top: 0;
//      right: -1000%;
//      bottom: 0;
//      left: -1000%;
//      content: "";
//      display: block;
//      background: #f3f3f3;
//      z-index: -1;
//    }
//  }
//
//  .serviceMenu {
//    margin: 0 -24px 0 0;
//    letter-spacing: -0.4em;
//
//    > .service {
//      display: inline-block;
//      vertical-align: top;
//      margin: 0 24px 0 0;
//      width: calc(100% / 2 - 24px);
//      letter-spacing: 0;
//
//      .menu {
//        position: relative;
//        box-sizing: border-box;
//        margin: 0 0 33px;
//        border: 3px solid #000000;
//        padding: 28px 30px;
//        background: #ffffff;
//
//        > *:last-child {
//          margin-bottom: 0 !important;
//        }
//
//        &::before {
//          position: absolute;
//          top: -3px;
//          right: auto;
//          bottom: auto;
//          left: 50%;
//          content: "";
//          display: block;
//          margin: 0 0 0 -50px;
//          border-top: 4px solid #f5c51e;
//          width: 100px;
//        }
//
//        > .heading {
//          margin: 0 0 20px;
//          border-bottom: 2px solid #cccccc;
//          padding: 0 0 10px;
//          font-size: 2.0rem;
//          text-align: center;
//        }
//      }
//
//      .point {
//        position: relative;
//        display: table;
//        width: 100%;
//        background: #ffffff;
//
//        &::after {
//          position: absolute;
//          top: -22px;
//          left: 50%;
//          margin-left: -12px;
//          content: "";
//          display: block;
//          border: solid #000000;
//          border-color: #000000 transparent transparent;
//          border-width: 13px 12px 0;
//          width: 0;
//          line-height: 1;
//        }
//
//        > .inner {
//          display: table-cell;
//          border: 3px solid #ff6633;
//          padding: 25px 20px;
//          color: #ff6633;
//          font-size: 2.0rem;
//          font-weight: bold;
//          vertical-align: middle;
//          text-align: center;
//
//          > *:last-child {
//            margin-bottom: 0 !important;
//          }
//        }
//      }
//    }
//  }
//
//  .taskList {
//    display: table;
//    margin: 30px 0 20px;
//    width: 100%;
//    text-align: left;
//
//    > {
//      *:last-child {
//        margin-bottom: 0 !important;
//      }
//
//      li {
//        display: table-row;
//
//        > span {
//          display: table-cell;
//          border-top: 1px solid #ffffff;
//          padding: 12px 25px;
//          vertical-align: middle;
//
//          &:nth-child(1) {
//            width: 90px;
//            font-weight: bold;
//            text-align: center;
//          }
//
//          &:nth-child(2) {
//            background: #f3f3f3;
//          }
//        }
//
//        &:nth-child(1) > span {
//          border-top: none;
//        }
//      }
//    }
//
//    &.m-problem > li > span:nth-child(1) {
//      background: #656ab8;
//      color: #ffffff;
//    }
//
//    &.m-improvement > li > span:nth-child(1) {
//      background: #f5c51e;
//    }
//  }
//
//  .improvement {
//    margin: 20px 0;
//    text-align: center;
//
//    > span {
//      overflow: hidden;
//      display: inline-block;
//      text-indent: 200%;
//      white-space: nowrap;
//      vertical-align: middle;
//      background-image: url('../../service/images/mod_img_sprite.//png');
//      background-repeat: no-repeat;
//      width: 124px;
//      height: 60px;
//      background-position: 0 0;
//    }
//  }
//
//  .makerBox {
//    margin: 0 -24px 0 0;
//    letter-spacing: -0.4em;
//
//    > .maker {
//      display: inline-block;
//      vertical-align: top;
//      box-sizing: border-box;
//      margin: 0 24px 30px 0;
//      border: 3px solid #000000;
//      padding: 18px 30px;
//      width: 480px;
//      letter-spacing: 0;
//
//      > *:last-child {
//        margin-bottom: 0 !important;
//      }
//
//      .heading {
//        margin: 0 0 20px;
//        border-bottom: 2px solid #cccccc;
//        padding: 0 0 10px;
//        text-align: center;
//        font-size: 2.0rem;
//        font-weight: bold;
//      }
//    }
//  }
//
//  .mod-relatedSection {
//    margin: 0 auto 80px;
//    width: 984px;
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail .lead {
//    padding: 40px 10px;
//    width: auto;
//
//    .mod-introductionBox + p {
//      margin: 20px 0 0;
//      font-size: 1.4rem;
//      text-align: left;
//    }
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail {
//    .serviceSection01, .serviceSection02 {
//      padding: 40px 10px;
//      width: auto;
//    }
//
//    .serviceSection01::before, .serviceSection02::before {
//      margin-left: -50px;
//      border-top-width: 2px;
//      width: 100px;
//    }
//
//    .serviceSection01 > .mod-textHeading, .serviceSection02 > .//mod-textHeading {
//      margin-bottom: 15px;
//    }
//
//    .serviceSection01::before {
//      top: -2px;
//    }
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail .serviceMenu {
//    margin-right: 0;
//
//    > {
//      *:last-child {
//        margin-bottom: 0 !important;
//      }
//
//      .service {
//        display: block;
//        margin: 0 0 30px;
//        width: auto;
//
//        .menu {
//          margin-bottom: 20px;
//          border-width: 2px;
//          padding: 12px 14px;
//
//          &::before {
//            display: none;
//          }
//
//          > .heading {
//            margin-bottom: 15px;
//            border-bottom-width: 1px;
//            font-size: 1.6rem;
//          }
//        }
//
//        .point {
//          &::after {
//            position: absolute;
//            top: -13px;
//            margin-left: -6px;
//            content: "";
//            display: block;
//            border: solid #000000;
//            border-color: #000000 transparent transparent;
//            border-width: 7px 6px 0;
//            width: 0;
//            line-height: 1;
//          }
//
//          > .inner {
//            border-width: 2px;
//            padding: 12px 14px;
//            font-size: 1.6rem;
//          }
//        }
//      }
//    }
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail .taskList {
//    margin: 15px 0 10px;
//
//    > li > span {
//      padding: 6px 10px;
//
//      &:nth-child(1) {
//        width: 60px;
//      }
//    }
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail .improvement {
//    margin: 10px 0;
//
//    > span {
//      overflow: hidden;
//      display: inline-block;
//      text-indent: 200%;
//      white-space: nowrap;
//      vertical-align: middle;
//      background-image: url('../../service/images/mod_img_sprite.//png');
//      background-repeat: no-repeat;
//      width: 62px;
//      height: 30px;
//      background-position: 0 0;
//      -moz-background-size: 62px auto;
//      -o-background-size: 62px auto;
//      -webkit-background-size: 62px auto;
//      background-size: 62px auto;
//    }
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail .makerBox {
//    margin: 0 0 20px;
//
//    > .maker {
//      display: block;
//      margin: 0 0 20px;
//      border-width: 2px;
//      padding: 12px 15px;
//      width: auto;
//
//      .heading {
//        margin-bottom: 10px;
//        border-bottom-width: 1px;
//        padding-bottom: 5px;
//        font-size: 1.6rem;
//      }
//
//      &:last-child {
//        margin-bottom: 0;
//      }
//    }
//  }
//}
//
//@media screen and (max-width: 640px) {
//  .detail .mod-relatedSection {
//    margin-bottom: 40px;
//    padding: 40px 10px 0;
//    width: auto;
//  }
//}

.service {
  &__bg {
    &__01 {
      background: linear-gradient(
          to left,
          transparent 0%,
          transparent 70%,
          #fff 100%
        ),
        url(../images/service/img_service_h1.png) center center / cover
          no-repeat;
    }
  }
  //----------common
  &__inner {
    @include sp {
      padding: 0 20px;
    }
    @include tabpc {
      min-width: 1100px;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 20px;
    }
  }
  img {
    max-width: 100%;
    // width: auto;
  }
  //第二階層
  .sec-level2 {
    &__inner {
      @include sp {
      }
      @include tabpc {
        margin-top: 100px;
        margin-bottom: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .box {
        @include sp {
          margin: 0 auto 17px;
        }
        @include tabpc {
          margin-bottom: 8px;
        }
      }
    }
  }
  //第三階層共通
  .sec-level3 {
    $sec-level3: '.sec-level3';
    &__ttl {
      @include sp {
        margin-top: 27px;
        margin-bottom: 12px;
      }
      @include tabpc {
        margin-top: 87px;
        margin-bottom: 25px;
      }
    }
    &__intro {
      @include sptab {
        margin-bottom: 32px;
        font-size: 1.3rem;
        line-height: 1.9rem;
      }
      @include tabpc {
        margin-bottom: 90px;
        font-size: 1.5rem;
      }
    }
    &__heading01 {
      font-weight: bold;
      @include sptab {
        margin: 0 -5px 25px;
      }
      @include tabpc {
        margin-bottom: 40px;
      }
    }
    &__wrap {
      @include tabpc {
        display: flex;
        justify-content: space-between;
      }
      #{$sec-level3} {
        &__topic {
          @include tabpc {
            text-align: left;
            padding: 40px 20px 50px;
            width: 48%;
          }
        }
      }
    }
    &__topic {
      background: $gray;
      text-align: center;
      @include sp {
        padding: 15px;
        margin-bottom: 27px;
      }
      @include tabpc {
        margin-bottom: 31px;
        padding: 40px 0 50px;
      }
      .text-line01 {
        position: relative;
        @include sp {
          margin-bottom: 40px;
          font-size: 1.3rem;
        }
        @include tabpc {
          margin-bottom: 45px;
          font-size: 1.7rem;
        }
        &:after {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
          content: "";
          width: 0;
          height: 0;

          @include sp {
            bottom: -17px;
            bottom: -23px;
            border-right: 12px solid transparent;
            border-top: 14px solid $green;
            border-left: 12px solid transparent;
          }
          @include tabpc {
            bottom: -29px;
            border-right: 16px solid transparent;
            border-top: 18px solid $green;
            border-left: 16px solid transparent;
          }
        }
      }
      .text-line02 {
        font-weight: bold;
        @include sp {
          font-size: 1.5rem;
        }
        @include tabpc {
          font-size: 2.2rem;
        }
      }
    }
    &__img01 {
      text-align: center;
      @include sp {
        position: relative;
        margin-bottom: 65px;
      }
      @include tabpc {
        margin-bottom: 100px;
      }
      .icon-glass {
        @include sp {
          position: absolute;
          width: 26px;
          right: 0;
          bottom: -40px;
        }
      }
    }
  }
  //第四階層共通
  .sec-level4 {
    &__ttl {
      @include sp {
        margin-top: 27px;
        margin-bottom: 12px;
      }
      @include tabpc {
        margin-top: 87px;
        margin-bottom: 25px;
      }
    }
    &__intro {
      @include sptab {
        margin-bottom: 32px;
        font-size: 1.3rem;
        line-height: 1.9rem;
      }
      @include tabpc {
        margin-bottom: 90px;
        font-size: 1.5rem;
      }
    }
    &__heading01 {
      font-weight: bold;
      @include sptab {
        margin: 0 -5px 25px;
      }
      @include tabpc {
        margin-bottom: 40px;
      }
    }
    &__text01 {
      font-weight: bold;
      @include sp {
        margin-bottom: 10px;
      }
      @include tabpc {
        margin-bottom: 30px;
        font-size: 2.2rem;
        line-height: 3.3rem;
      }
    }
    &__text02 {
      @include sp {
        margin-bottom: 20px;
      }
      @include tabpc {
        margin-bottom: 43px;
      }
    }

    &__img01 {
      text-align: center;
      @include sp {
        position: relative;
        margin-bottom: 65px;
      }
      @include tabpc {
        margin-bottom: 100px;
      }
      .icon-glass {
        @include sp {
          position: absolute;
          width: 26px;
          right: 0;
          bottom: -40px;
        }
      }
    }
  }
  //第五階層共通
  .sec-level5 {
    &__ttl {
      @include sp {
        margin-top: 27px;
        margin-bottom: 12px;
      }
      @include tabpc {
        margin-top: 87px;
        margin-bottom: 25px;
      }
    }
    &__intro {
      @include sptab {
        margin-bottom: 32px;
        font-size: 1.3rem;
        line-height: 1.9rem;
      }
      @include tabpc {
        margin-bottom: 90px;
        font-size: 1.5rem;
      }
    }
    &__list {
      margin-left: 20px;
      margin-bottom: 20px;
      .list-item {
        list-style: disc;
        @include sptab {
          font-size: 1rem;
        }
      }
    }
    &__heading01 {
      font-weight: bold;
      @include sptab {
        margin-bottom: 27px;
        font-size: 1.7rem;
        line-height: 2.4rem;
      }
      @include tabpc {
        margin-bottom: 40px;
      }
    }
    &__text01 {
      @include sp {
        margin-bottom: 30px;
      }
      @include tabpc {
        margin-bottom: 90px;
        font-size: 1.5rem;
        line-height: 2.6rem;
      }
    }
    .trouble {
      @include sp {
        margin-bottom: 35px;
      }
      @include tabpc {
        margin-bottom: 93px;
      }
      &__ttl {
        text-align: center;
        @include sp {
          margin-bottom: 20px;
          font-size: 1.5rem;
        }
        @include tabpc {
          margin-bottom: 40px;
          font-size: 2.2rem;
        }
      }
      &__detail {
        background: #f5f6f8;
        @include sp {
          padding: 20px 15px;
          margin-bottom: 25px;
        }
        @include tabpc {
          padding: 45px 50px;
          margin-bottom: 50px;
        }
      }
      &__list {
        padding: 0;
        @include sp {
        }
        @include tabpc {
          display: flex;
          justify-content: space-between;
        }
        .list-item {
          position: relative;
          @include sp {
            padding-left: 35px;
            margin-bottom: 15px;
            font-size: 1.3rem;
          }
          @include tabpc {
            width: 48%;
            max-width: 542px;
            padding-left: 38px;
            &-notIndention{
              width: 100%;
              max-width: 100%;
            }
          }
          &:after {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            display: block;
            background-image: url("../images/service/img_service_trouble_01.svg");
            background-repeat: no-repeat;
            background-size: cover;
            @include sp {
              width: 23px;
              height: 21px;
            }
            @include tabpc {
              width: 30px;
              height: 27px;
            }
          }
          &.is-single {
            @include tabpc {
              width: 100%;
              max-width: inherit;
              padding-left: 38px;
            }
          }
        }
      }
      &__ans {
        .ansblock {
          border: solid 1px $black02;
          overflow: hidden;
          @include sp {
            margin-bottom: 15px;
            border-radius: 5px;
          }
          @include tabpc {
            margin-bottom: 20px;
            display: flex;
            border-radius: 10px;
          }
          &__ttl {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $black02;
            color: #ffffff;
            font-weight: bold;
            @include sp {
              height: 37px;
              font-size: 1.5rem;
            }
            @include tabpc {
              width: 200px;
              min-height: 147px;
              font-size: 2.2rem;
            }
            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              @include sp {
                width: 0;
                height: 0;
                left: 0;
                right: 0;
                bottom: -11px;
                margin: auto;
                border-style: solid;
                border-width: 12px 9px 0 9px;
                border-color: $black02 transparent transparent transparent;
              }
              @include tabpc {
                top: 0;
                bottom: 0;
                right: -20px;
                margin: auto 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 15px 0 15px 20px;
                border-color: transparent transparent transparent $black02;
              }
            }
          }
          &__ans {
            @include sp {
              padding: 30px 15px 15px;
            }
            @include tabpc {
              width: calc(100% - 200px);
              padding: 0 30px 0 50px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .flow {
      @include sp {
      }
      @include tabpc {
        margin-bottom: 70px;
      }
      &__ttl {
        @include sp {
          margin-bottom: 5px;
          font-size: 1.3rem;
        }
        @include tabpc {
          //margin-bottom: 27px;
          margin-bottom: 10px;
          font-size: 1.7rem;
        }
      }

      &__img {
        text-align: center;
        @include sp {
          position: relative;
          margin-bottom: 65px;
        }
        @include tabpc {
          max-width: 1070px;
          margin: 0 auto 100px;
        }
        .icon-glass {
          @include sp {
            position: absolute;
            width: 26px;
            right: 0;
            bottom: -40px;
          }
        }
      }
    }
    &__contact {
      text-align: center;
      @include sp {
        margin-bottom: 65px;
      }
      @include tabpc {
        margin-bottom: 150px;
      }
      .button {
        margin: 0 auto;
        @include tabpc {
          width: 312px;
        }
      }
    }
  }

  //service共通
  &__support {
    background: $gray02;
    @include sp {
      padding-top: 46px;
      padding-bottom: 60px;
    }
    @include tabpc {
      padding-top: 100px;
    }
    .inner {
      max-width: 1300px;
      margin: 0 auto;
      @include sp {
        padding: 0 10px;
        width: 100%;
      }
      @include tabpc {
        padding: 0 20px;
        // min-width: 1100px;
        min-width: 1024px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .box {
      @include sp {
        margin-bottom: 40px;
      }
      @include tabpc {
      }
    }
  }
  &__case {
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1240px;
    min-width: inherit;
    @media screen and (max-width: 1260px) and (min-width: 769px) {
      width: 970px;
      margin: 0 auto;
    }
    @include sp {
      //width: 300px;
      width: 100%;
    }

    .ttl {
      @include sp {
        margin-bottom: 25px;
      }
      @include tabpc {
        margin-bottom: 50px;
      }
    }
    .slide {
      //display: flex;
      &__box {
        padding: 0 3px 16px;
        @include sp {
          width: 220px;
        }
      }
      .slick-prev,
      .slick-next {
        position: absolute;
        font-size: 0;
        z-index: 9;
        @include sp {
          top: 89px;
        }
        @include tabpc {
          top: 45%;
        }
      }
      .slick-next {
        @include sp {
          right: 0px;
        }
        @include tabpc {
          right: -50px;
        }
      }
      .slick-prev {
        @include sp {
          left: 0px;
        }
        @include tabpc {
          left: -50px;
        }
      }
      .slick-prev::before {
        content: url("../images/service/img_slider_arrow_r01.svg");
      }
      .slick-next::before {
        content: url("../images/service/img_slider_arrow_l01.svg");
      }
    }
    .box {
      margin: 0 auto;
    }
  }
}
