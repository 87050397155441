@charset "UTF-8";

.header__hide {
    
}
.header__move {
    
}
header {
    width: 100%;
    position: fixed;
    z-index: 300;
}
.header {
    @include position();
    &__wrapper {
        @include flex(between,center);
        position: relative;
        max-width: none;
        // min-width: 1100px;
        min-width: 1024px;
        height: 70px;
        padding: 0;
        @include sp {
            max-width: auto;
            min-width: auto;    
            width: 100%;
            height: 40px;
        }
    }
    &__logo {
        margin: auto auto auto 20px;
        >a {
            display: block;
        }
        @include sp {
            width:40%;
            margin: auto auto auto 4px;
            img{
                max-width: 210px;
                max-height: 28px
            }
        }
    }
    &__menu {
        @include flex(start,center);
        >li {
            >a,>span,>p {
                @include position();
                z-index: 330;
                padding: 15px;
                cursor: pointer;
                transition: all .3s ease;
                &:hover {
                    font-weight: bold;
                }
            }
            &:last-of-type {
                >a,>p {
                    padding: 20px 0 20px 20px;
                }
            }
        }
    }
    &__spmenu {
        width: 40px;height: 40px;
    }
    &__search {
        &__block {
            position: relative;
            width: 70px;height: 70px;
            cursor: pointer;
            &:hover {
            }
            >img{
                @include position(absolute,0,0,0,0);
                width: 30%;height: 30%;
                margin: auto;
                background:url(../images/common/icon_search.svg) center center /100% no-repeat;
                z-index: 330;
            }
        }
    }
    &__contact {
        >a {
            @include flex(center,center);
            height: 70px;
            font-weight: bold;
            text-align: center;
            background: $yellow;
            padding: 20px;
            &:hover {
                background: $black02;
                color: $white;
            }
            @include sp {
                height: 40px;
                padding: 10px 20px;
            }
        }
    }
}

.sp-d-menu {
    @include position(absolute,40px,n,n,0);
    width: 100%;
    &__wrapper {
        @include position();
    }
    &__bg {
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(0,0,0,.2);
        z-index: 510;
    }
    .d-menu__search {
        padding: 10px 24px;
        background: $black;
        @include position;
        z-index: 510;
        >input {
            background: $white;
        }
        &:before {
            right: 36px;
        }
    }
    .lv__01,.lv__02 {
        >li {
            border-bottom: 1px solid $border_gray;
            >p {
                @include position();
                &:before {
                    content: "";
                    @include position(absolute,0,55px,n,n);
                    width: 1px;height: 100%;
                    background: $border_gray;
                }
            }
            >a,>p {
                @include position();
                &:after {
                    content:"";
                    @include position(absolute,0,25px,0,auto);
                    width: 10px;height: 10px;
                    border-top: 1px solid $default;
                    border-right: 1px solid $default;
                    margin: auto;
                    transform: rotate(45deg);
                }
                display: block;
                padding: 20px 24px;
                line-height: 1.4rem;
            }
        }
    }
    .lv__01 {
        font-weight: bold;
        // position: fixed;
        width: 100%;
        z-index: 999;
        overflow-y: auto;
        height: calc(100vh - 130px);
        z-index: 500;
        >li {
            background: #fff;
            @include position;
            z-index: 520;
            span{
                display: flex;
                height: 54px;
                text-align: center;
                align-items: center;
                justify-content: space-between;
                a{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding-left: 24px;
                }
                p{
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 54px;
                    min-width: 54px;
                    height: 100%;
                    border-right: 1px solid #c3c9d9;
                    border-left: 1px solid #c3c9d9;
                    &:after{   
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 25px;
                        bottom: 0;
                        width: 10px;
                        height: 10px;
                        border-top: 1px solid #333;
                        border-right: 1px solid #333;
                        margin: auto;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
    .lv__02 {
        border-top: 1px solid $border_gray;
        font-weight: normal;
        background: $gray;
        >li:last-of-type{
            border-bottom: 0;
        }
    }
    .lv__03 {
        font-size: 1.2rem;
        font-weight: normal;
        border-top:1px solid $border_gray;
        padding: 10px 0;
        >li {
            >a{
                @include position();
                &:after {
                    content:"";
                    @include position(absolute,0,25px,0,auto);
                    width: 10px;height: 10px;
                    border-top: 1px solid $default;
                    border-right: 1px solid $default;
                    margin: auto;
                    transform: rotate(45deg);
                }
                display: block;
                padding: 10px 24px;
                line-height: 1.4rem;                        
            }
        }
    }
}
.sp__accordion {
    @keyframes sp__accodionShow {
        from {
            opacity: 0;
            transform: translateY(5px);
        }
        to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .is-open {
        display: block;
        animation: sp__accodionShow 0.3s linear 0s;
    }
    .is-close {
        display: none;
    }
    .is-actve {
        color: $green;
    }
}
.d-menu {
    @include position(absolute,0,0,n,0);
    width: 100vw;
    min-width: 1100px;
    min-height: 100%;
    bottom: 0;
    &__base {
        //@include position();

    }
    &__bg {
        @include position(fixed,70px,n,n,0);
        margin:auto;
        width: 100vw;height: 100vh;
        background: rgba(0,0,0,.2);
        min-width: 1100px;
        min-height: 100%;
        //z-index:320;
    }
    &__wrapper {
        //@include position();
        //border-radius: 10px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @include position(absolute,70px,0,n,0);
        background: $black02;
        z-index: 330;
        //min-width: 1100px;

    }
    #menuService {
        //right: 325px;
        //width: 750px;
        padding:15px 20px 30px;

        .d-menu__item {
            width: calc(100% / 6);
            min-width: 200px;
        }
    }
    #menuCompany {
        //right: 232px;
        //width: 580px;
        padding:15px 20px 30px;

        .d-menu__item {
            width: calc(100% / 3);
            max-width: 200px;
            min-width: 200px;
        }
        .d-menu__line {
            @include flex(center,center,wrap)
        }    
    }
    #menuSearch {
        right: 0;
        left: auto;
        width: 350px;
        padding: 20px 20px;
        min-width: 350px;
        max-width: 350px;
        .d-menu__line {
            min-width: auto;
            max-width: auto;
        }    
    }
    &__line {
        min-width: 1060px;
        max-width: 1240px;
        margin: 0 auto;
        @include flex(center,center,wrap);
        &:not(:first-of-type){
            border-top: 1px solid $border_gray;
            margin: 15px auto 0;
        }
    }
    &__search {
        @include position();
        &:before {
            content: "";
            @include position(absolute,0,16px,0,n);
            width: 20px;height: 20px;
            margin: auto;
            background: url(../images/common/icon_search.svg) center center /cover no-repeat;
        }
        width: 100%;
        >input {
            width: 100%;
            border: 1px solid $white;
            background: $white;
            border-radius: 50px;
            padding: 10px 40px 10px 20px;
        }
    }
    &__item {
        align-self: stretch;
        >a {
            display: block;
            color: $white;
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0;
            padding: 12px 20px;
            border: 1px solid $white;
            border-radius: 10px;
            margin: 15px 10px 0;
            &:hover {
                color: $black02;
                background: $white;
            }
        }
        >ul {
            margin: 10px 0 0 0;
            >li {
                >a {
                    @include position();
                    font-size: 1.3rem;
                    color: $white;
                    padding: 4px 0 4px 26px;
                    &:before {
                        content:"";
                        @include position(absolute,0,n,0,10px);
                        width:0;height: 0;
                        margin: auto;
                        border-top: 4px solid transparent;
                        border-left:  8px solid $white;
                        border-bottom: 4px solid transparent;
                    }
                    &:hover {
                        color: #a1a1a1;
                    }
                }
            }
        }
    }
}