@charset "UTF-8";

.bg-white {
    background: $white !important;
}
.svg-bg-green{
    fill: $green !important;
    stroke: $green !important;
}
.svg-bg-black{
    fill: #141414 !important;
    stroke: #141414 !important;
}
