/* ========================================================= */
/* BASE                                                      */
/* ========================================================= */
html {
  font-family: $font-family;
  font-size: $font-size;
  letter-spacing: .1rem;
  @include sp {
    
  }
}

body {
  font-size: 1.6rem;
  color: $default;
  // background: $black;
  min-width: 1100px;
  overflow: hidden;
  @include sp{
    font-size: 1.4rem;
    width: 100%;
    min-width: auto;
  }
}

:focus {
  outline: 0;
}

button,
input,
textarea,
optgroup {
  &:focus {
    box-shadow: 0 0 0 2px color(focus);
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  display: block;
  color: $default;
  text-decoration: none;
  transition: all  0.3s ease;
  &:hover {
    //color: $green;
    // opacity: .7;
  }
}

.bread {
  @include position();
  z-index: 200;
  @include flex(start,center);
  font-size: 1.2rem;
  max-width: none;
  padding: 14px 20px;
  background: $gray;
  @include sp {
    font-size: .9rem;
    padding: 8px 10px;
    flex-wrap: wrap;
  }

  >li:not(:last-of-type) {
    @include position();
    padding: 0 16px 0 0;
    &:before,&:after {
      content: "";
      @include position(absolute,30%,7px,n,n);
      width: 1px;height: 9px;
      background: $default;
      @media all and (-ms-high-contrast: none) {
        top:1px;
      }
      @include sp {
        height: 7px;
      }
    }
    &:before {
      margin: 2px 0 0 0;
      transform: rotate(60deg);
      @include sp {
        top: 16%;
      }
    }
    &:after {
      margin: -2px 0 0 0;
      transform: rotate(-60deg);
      @include sp {
        top: 18%;
      }
    }
  }
}
.spmenu {
  &__block {
    @include position();
    width: 40px;height: 40px;
    >span {
        @include position(absolute,0,0,0,0);
        width:50%;height: 1px;
        margin: auto;
        background: $black;
        &:first-of-type {
          top:-14px;
        }
        &:last-of-type {
          top:14px;
        }
    }
  }
}
main {
  //overflow: hidden;
  padding: 70px 0 0 0;
  &.toppage{
    padding: 140px 0 0 0;
  }
  @include sp {
    padding: 40px 0 0 0;
  }
}

.container {
  min-width: 1140px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  @include sp {
    min-width: auto;
    max-width: auto;
    padding: 0 20px;
  
  }
}

.sec {
  &__h1 {
    @include position();
    z-index: 200;
    @include flex(between,center);
    overflow: hidden;
    height: 330px;
    background: $white;
    @include sp {
      @include flex(between,center,wrap);
      flex-direction: column-reverse;
      height: auto;
    }
    &__item {
      &:first-of-type{
        width: 50%;
        padding: 20px;
      }
      &:last-of-type{
        width: 50%;height: 330px;
        @include position;
        >img {
          @include position(absolute,0,0,0,0);
          width: auto;height: 100%;
        }
      }
      @include sp {
        &:first-of-type{
          width: 100%;
          padding:20px;
        }
        &:last-of-type{
          width: 100%;height: 160px;
          @include position;
          >img {
            @include position(absolute,0,0,0,0);
            width: auto;height: 100%;
          }
        }  
      }
      >p {
        &:nth-of-type(1) {
          font-size: 7.2rem;
        }
        &:nth-of-type(2) {
          font-size: 1.5rem;
          font-weight: bold;
          margin: -20px 0 0 0;
        }
        &:nth-of-type(3) {
          font-size: 1.7rem;
          margin: 40px 0 0 0;
        }
        @include sp {
          &:nth-of-type(1) {
            font-size: 4.2rem;
          }
          &:nth-of-type(2) {
            font-size: 1.0rem;
            font-weight: bold;
            margin: -10px 0 0 0;
          }
          &:nth-of-type(3) {
            font-size: 1.3rem;
            margin: 20px 0 0 0;
          }  
        }
      }
    }
  }
  &__h2 {
    @include position();
    font-size:3.2rem;
    letter-spacing: 0;
    padding: 0 0 16px 0;
    &:before {
      content: "";
      @include position(absolute,n,n,0,n);
      width: 100%;height: 4px;
      //background:linear-gradient(to right,$yellow, $green);
      background:$black02;
    }
    @include sp {
      font-size:2.4rem
    }
  }
  &__text {

  }
}

.font {
  &__jost {
    font-family: $font-famiily-jost;
  }
}

.tools {
  @include position(fixed,n,10px,10px,n);
  @include flex(end,center,nowrap);
  width: 130px;
  z-index: 500;
  @include sp {
    @include position(fixed,n,10px,30px,n);    
  }
  &__item {
    width: 46%;
    >p {
      >img {
        width: 100%;;
      }
    }
  }
  &__text {
    @include position(fixed,n,140px,10px,n);
    font-size: 1.3rem;
    font-weight: bold;
    // border: 1px solid $border_gray;
    padding: 10px;
    >p {
      @include position();
      border: 1px solid $border_gray;
      background: $white;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      &:before {
        content:"";
        @include position(absolute,0,0,0,n);
        width: 10px;height: 10px;
        margin: auto;
        border-right: 10px solid transparent;
        border-bottom:  20px solid $white;
        border-left: 10px solid transparent; 
      }  
    }
  }
}

.alert {
  &__text {
    color: $alert_red;
  }
}