@charset "UTF-8";

$default: #333;
$black: #000;
$white: #fff;
$yellow: #fecb0a;
$yellow_shadow: #c79f08;
$green: #0aad8a;
$dark_green: #00624d; //rgba(0,98,77,.7)
$gray:   #f5f6f8;
$dark_gray: #c6c9d3;

$drak_gray2: #aeb2c0;
$content_bg: #e6e8ed;
$light_green: #3bbda1;
$corona_color:#f8585a;
$light_corona_color:#f9797b;

$border_gray: #c3c9d9;
$alert_red :#c40000;

$main_color: #0079C4;
$sub_color: #18358E;
$background_color: #DFEAF1;
$btn-color-orange: #EF7B00;
$font-color-orange: #0079C4;

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.case {
    position: relative;
    z-index: 3;
    @include sp {
        margin-top: 45px;
    }
    &__inner {
        .sec__ttl {
            text-align: center;
        }

        .sec__h2 {
            display: inline-block;
            padding-bottom: 5px;
            font-size: 3rem;
            @include sp {
                font-size: 2.1rem;
            }
            &::before {
                height: 2px;
            }
            & > span {
                position: relative;
                padding: 0 14px;
                &::before,&::after {
                    content: "";
                    @include position(absolute,n,n,-16px,n);
                    width: 12px;
                    height: 12px;
                    border-radius: 10px;
                    background: $white;
                    @media all and (-ms-high-contrast: none) {
                      bottom: -10px;
                    }
                    @include sp {
                        @include position(absolute,n,n,-14px,n);
                    }
                }

                &::before {
                    left: -10px;
                    background-color: $black02;
                }

                &::after {
                    right: -10px;
                    background-color: $black02;
                }
            }
        }
        .coming-soon{
            .case__link{
                height: 100%;
            }
            .case__content{
                display: flex;
                height: 100%;
                align-items: center;
            }
            .case__ti{
                margin: 0 auto;
            }
            
        }
    }
    &__row {
        // min-width: 1100px;
        min-width: 1024px;
        max-width: 1280px;
        margin: 40px auto 80px;
        padding: 0 20px;
        @include sp {
            min-width: inherit;
            max-width: inherit;
            padding: 0;
        }
        .slick-list {
            padding: 5px 0 15px;
            @include sp {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
            }
        }

        .slick-arrow {
            @include position(absolute,50%,n,n,n);
            z-index: 10;
            width: 39px;
            height: 39px;
            margin-top: -20px;
            padding-top: 40px;
            overflow: hidden;
            border-radius: 50%;
            background-color: $black02;
            transition: all .3s ease;
            &.slick-prev {
                left: -20px;
                &::before {
                    left: calc(50% - 4px);
                    transform: rotate(225deg);
                }
            }
            &.slick-next {
                right: -20px;
                &::before {
                    left: calc(50% - 8px);
                    transform: rotate(45deg);
                }
            }
            &::before {
                position: absolute;
                top:calc(50% - 6px);
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                border-top: solid 2px $white;
                border-right: solid 2px $white;
                transition: all .3s ease;
            }
            &:hover {
                background-color: $green;
                transition: all .3s ease;
                &::before {
                    border-top-color: $white;
                    border-right-color: $white;
                }
            }
        }
    }

    &__column {
        margin: 0 15px;
        box-shadow: 11px 11px 7px rgba(0,0,0,.05);
        background-color: $gray02;
        @include sp {
            margin: 0 15px;
        }
    }

    &__link {
        display: block;
        position: relative;
        top: 0;
        &:hover {
            opacity: $opacity;
        }
    }

    &__pic {
        text-align: center;
    }

    &__content {
        padding: 20px 20px 50px;
    }

    &__stats {
        display: flex;
        flex-wrap: wrap;
        &--service{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__tag {
        display: flex;
        padding: 5px 10px;
        font-size: 1.2rem;
        border-radius: 4px;
        color: $black02;
        background: $white;
        align-items: center;
        text-align: center;
        margin-bottom: 5px;
        margin-right: 5px;
        word-break: keep-all;
        
    }

    &__ti {
        margin-top: 15px;
        font-size: 1.7rem;
    }

    &__txt {
        margin-top: 18px;
        font-size: 1.5rem;
        line-height: 1.6;
    }

    //導入企業一覧
    &__company {
        position: relative;
        z-index: 3;
        
        @include sp {
            margin-top: 40px;
        }
        .sec__h3 {
            font-size: 2rem;
            text-align: center;
            @include sp {
                font-size: 1.7rem;
            }
        }
    }

    &__slide {
        margin-top: 40px;
        @include sp {
            margin-top: 30px;
        }
        .slick-track {
            @include flex(center,center,wrap);
        }
    }

    &__company_column {
        float: none;
        margin: 0 25px;
        img {
            width: auto;
        }
    }

    &__more-block {
        margin-top: 80px;
        text-align: center;
        @include sp {
            margin-top: 40px;
        }
        & > .more-btn {
            display: inline-block;
            position: relative;
            top: 0;
            width: 100%;
            max-width: 278px;
            box-shadow: 11px 11px 7px rgba(0,0,0,.08);
            background-color: $black02;
            border-radius: 10px;
            padding: 20px;
            color: $white;
            font-size: 1.7rem;
            text-align: center;
            @include sp {
                max-width: 228px;
                padding: 17px 20px;
                font-size: 1.5rem;
            }
            &::after {
                @include position(absolute,50%,20px,n,n);
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                margin-top: -6px;
                border-top: solid 2px $white;
                border-right: solid 2px $white;
                transform: rotate(45deg);
            }
            &:hover {
                opacity: $opacity;
                //top: -3px;
            }
        }
    }
}

.index { 
    &::before {
        //position: fixed;
        //top: 0;
        //left: 0;
        //z-index: 1;
        //content: "";
        //width: 100%;
        //height: 2457px;
        //background: url(../images/index/bg_mv_img01.png) 0 0/cover no-repeat;
        //@include sp {
        //    height: 100%;
        //    background: url(../images/index/bg_mv_img01_sp.png) 0 0 //no-repeat;
        //}
    }
    // header {
    //     position: relative;
    //     z-index: 10;
    //     &.bg-white {
    //         background-color: transparent !important;
    //     }
    // }

    .container {
        min-width: inherit;
        max-width: inherit;
        padding: 0;
    }
}

.container__index {
    margin-top: -70px;
    @include sp {
        margin-top: -40px;
    }
    @media all and (-ms-high-contrast: none) {
        overflow-x: hidden;
    }
    .mv {
        position: relative;
        z-index: 1;
        &.is-hide {
            &::before {
                display: none;
            }
        }
        //&::before {
        //    position: fixed;
        //    top: 0;
        //    left: 0;
        //    z-index: 1;
        //    content: "";
        //    width: 100%;
        //    height: 2457px;
        //    background: url(../images/index/bg_mv_img01.png) //0 0/cover no-repeat;
        //    @include sp {
        //        height: 100%;
        //        background: url(../images/index///bg_mv_img01_sp.png) 0 0 no-repeat;
        //    }
        //}
        &::after {
            @include position(absolute,0,n,n,0);
            z-index: 2;
            content: "";
            width: 100%;
            height: 1200px;
            //background: url(../images/index/bg_mv_img02.png) 0 0/cover no-repeat;
            @include sp {
                height: 100%;
                //background: url(../images/index/bg_mv_img02_sp.png) 0 0/contain no-repeat;
            }
        }

        &__deco {
            position: absolute;
            z-index: -1;
        }

        //MV
        $mvTopPc : 64px;//mv内のアイテムを上げた数値
        $mvTopSp : 22px;//mv内のアイテムを上げた数値
        &__deco.decoration-disc01 {
            top: -100px - $mvTopPc;
            left: 29%;
            width: 144px;
            height: 144px;
            @include sp {
                top: -50px - $mvTopSp;
                left: calc(50% - 36px);
                width: 72px;
                height: 72px;
            }
        }

        &__deco.decoration-disc02 {
            top: 648px - $mvTopPc;
            left: -157px;
            width: 260px;
            height: 260px;
            @include sp {
                top: 380px - $mvTopSp;
                left: -90px;
                width: 130px;
                height: 130px;
            }
        }

        &__deco.decoration-disc03 {
            top: 640px - $mvTopPc;
            right: 75px;
            width: 86px;
            height: 86px;
            @include sp {
                top: 410px - $mvTopSp;
                right: 45px;
                width: 43px;
                height: 43px;
            }
        }

        &__deco.decoration-disc04 {
            top: 45px - $mvTopPc;
            right: 25%;
            width: 125px;
            height: 125px;
            @include sp {
                top: 135px - $mvTopSp;
                right: 5%;
                width: 62px;
                height: 62px;
            }
        }

        //導入事例
        &__deco.decoration-disc05 {
            top: 130px - $mvTopPc;
            right: -100px;
            width: 198px;
            height: 198px;
            @include sp {
                top: -25px - $mvTopSp;
                right: -50px;
                width: 100px;
                height: 100px;
            }
        }

        //サービス
        &__deco.decoration-disc06 {
            top: -80px - $mvTopPc;
            left: 460px;
            width: 144px;
            height: 144px;
            @include sp {
                top: 125px - $mvTopSp;
                left: -50px;
                width: 100px;
                height: 100px;
            }
        }
        &__deco.decoration-disc07 {
            top: 695px - $mvTopPc;
            right: 110px;
            width: 86px;
            height: 86px;
            @include sp {
                top: 300px - $mvTopSp;
                right: 5px;
                width: 43px;
                height: 43px;
            }
        }
        &__deco.decoration-disc08 {
            top: 655px - $mvTopPc;
            left: -157px;
            width: 260px;
            height: 260px;
            @include sp {
                top: 715px - $mvTopSp;
                left: auto;
                right: -25px;
                width: 100px;
                height: 100px;
            }
        }
        &__deco.decoration-disc09 {
            bottom: 405px + $mvTopPc;
            right: 320px;
            width: 125px;
            height: 125px;
            @include sp {
                top: 1040px - $mvTopSp;
                left: -15px;
                width: 43px;
                height: 43px;
            }
        }
        &__deco.decoration-disc10 {
            bottom: 400px + $mvTopPc;
            left: 36px;
            width: 86px;
            height: 86px;
            @include sp {
                bottom: 450px + $mvTopSp;
                left: -80px;
                width: 100px;
                height: 100px;
            }
        }
        &__deco.decoration-disc17 {
            display: none;
            @include sp {
                display: block;
                bottom: 360px + $mvTopSp;
                right: 10px;
                width: 43px;
                height: 43px;
            }
        }

        &__deco.decoration-disc11 {
            top: 100px;
            right: -100px;
            width: 200px;
            height: 200px;
            @include sp {
                top: 70px + $mvTopSp;
                right: -60px;
                width: 100px;
                height: 100px;
            }
        } 
        &__deco.decoration-disc12 {
            top: 735px;
            left: 460px;
            width: 144px;
            height: 144px;
            @include sp {
                top: 230px + $mvTopSp;
                left: -10px;
                width: 43px;
                height: 43px
            }
        }
        &__deco.decoration-disc13 {
            bottom: 545px;
            left: -157px;
            width: 261px;
            height: 261px;
            @include sp {
                display: none;
                top: 580px + $mvTopSp;
                bottom: auto;
                right: -50px;
                width: 100px;
                height: 100px;
                left: auto;
            }
        }
        &__deco.decoration-disc14 {
            bottom: 685px;
            right: 115px;
            width: 86px;
            height: 86px;
            @include sp {
                left: -15px;
                right: auto;
                width: 43px;
                height: 43px;
                bottom: 900px + $mvTopSp;
            }
        }
        &__deco.decoration-disc15 {
            bottom: 240px;
            right: 325px;
            width: 125px;
            height: 125px;
            @include sp {
                bottom: 990px + $mvTopSp;
                right: -50px;
                width: 100px;
                height: 100px;
            }
        }
        &__deco.decoration-disc16 {
            bottom: 186px;
            left: 35px;
            width: 86px;
            height: 86px;
            @include sp {
                bottom: 115px + $mvTopSp;
                left:  -25px;
                width: 100px;
                height: 100px;
            }
        }

        //サークル(線)
        &__deco.decoration-circle01 {
            top: 236px;
            left: -32px;
            width: 86px;
            height: 86px;
            @include sp {
                top: 90px;
                left: -21px;
                width: 43px;
                height: 43px;
            }
        }
        &__deco.decoration-circle02 {
            top: 330px;
            right: -85px;
            width: 273px;
            height: 273px;
            @include sp {
                top: 550px;
                right: -15px;
                width: 43px;
                height: 43px;
            }
        }
        &__deco.decoration-circle03 {
            top: 760px;
            left: 200px;
            width: 198px;
            height: 198px;
            @include sp {
                top: 650px;
                left: 10px;
                width: 100px;
                height: 100px;
            }
        }
        &__deco.decoration-circle04 {
            top: 866px;
            right: -30px;
            width: 86px;
            height: 86px;
            @include sp {
                display: none;
            }
        }
        &__deco.decoration-circle05 {
            top: 1370px;
            left: 301px;
            width: 273px;
            height: 273px;
            @include sp {
                display: none;
            }
        }


        &__deco.decoration-circle06 {
            left: -20px;
            bottom: 0;
            width: 86px;
            height: 86px;
            @include sp {
                left: -20px;
                bottom: 10px;
                width: 43px;
                height: 43px;
            }
        }
        &__deco.decoration-circle07 {
            right: 200px;
            bottom: -225px;
            width: 198px;
            height: 198px;
            @include sp {
                right: 100px;
                bottom: -130px;
                width: 100px;
                height: 100px;
            }
        }



        &__deco.decoration-circle08 {
            top: 240px;
            left: -35px;
            width: 86px;
            height: 86px;
            @include sp {
                right: -50px;
                left: auto;
                top: 50px;
                width: 100px;
                height: 100px;
            }
        }
        &__deco.decoration-circle09 {
            top: 390px;
            right: -86px;
            width: 273px;
            height: 273px;
            @include sp {
                right: auto;
                left: 10px;
                top: 490px;
                width: 136px;
                height: 136px;
            }
        }
        &__deco.decoration-circle10 {
            top: 770px;
            right: -34px;
            width: 86px;
            height: 86px;
            @include sp {
                right: 30px;
                top: auto;
                width: 100px;
                height: 100px;
                bottom: 880px;
            }
        }        
        &__deco.decoration-circle11 {
            top: 825px;
            left: 280px;
            width: 198px;
            height: 198px;
            @include sp {
                right: -40px;
                top: auto;
                width: 100px;
                height: 100px;
                bottom: 500px;
                left: auto;
            }
        } 
        &__deco.decoration-circle12 {
            bottom: -55px;
            left: 280px;
            width: 273px;
            height: 273px;
            @include sp {
                left: 10px;
                bottom: -30px;
                width: 136px;
                height: 136px;
            }
        } 


        &__deco.decoration-circle13 {
            top: 540px;
            right: 365px;
            width: 198px;
            height: 198px;
            @include sp {
                top: 440px;
                right: 12%;
                width: 100px;
                height: 100px;
            }
        } 
        &__deco.decoration-circle14 {
            top: 1040px;
            left: -34px;
            width: 86px;
            height: 86px;
            @include sp {
                top: 1000px;
                right: 0;
                width: 100px;
                height: 100px;
                left: auto;
            }
        } 
        &__deco.decoration-circle15 {
            bottom: 795px;
            right: -80px;
            width: 273px;
            height: 273px;
            @include sp {
                bottom: 1125px;
                right: auto;
                left: 10px;
                width: 136px;
                height: 136px;
            }
        } 
        &__deco.decoration-circle16 {
            bottom: 410px;
            left: 200px;
            width: 198px;
            height: 198px;
            @include sp {
                bottom: 615px;
                right: 25px;
                left: auto;
                width: 100px;
                height: 100px;
            }
        } 
        &__deco.decoration-circle17 {
            bottom: 500px;
            right: -34px;
            width: 86px;
            height: 86px;
            @include sp {
                bottom: 300px;
            }
        } 


        &__bg {
            position: relative;
            z-index: 4;
            padding: 0 0 120px;
            @include sp {
                padding: 40px 0 65px;
            }  
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 1311px;
                z-index: 2;
                //background: url(../images/index/bg_mv_glow_img01.png) 0 0 no-repeat;
                @include sp {
                    z-index: -1;
                    height: 100%;
                    background: url(../images/index/bg_mv_glow_img01_sp.png) 0 0/contain no-repeat;
                }
            }
        }

        &__inner {
            position: relative;
            z-index: 4;
            // min-width: 1100px;
            min-width: 1024px;
            min-height: 914px;
            margin: 0 auto;
            @include sp {
                min-width: inherit;
                min-height: 465px;
            }
        }

        .lazy-line-painter {
            @include position(absolute,0,n,n,0);
            opacity: 0;
            &.is-show {
                opacity: 1;
            }
        }

        &__photo01 {
            @include position(absolute,0,n,n,-70%);
            z-index: 4;
            max-width: 600px;
            max-height: 600px;
            padding: 20px;
            .st0 {
                fill: none;
                stroke: url(#SVGID_1_);
                stroke-width: 1;
                stroke-dasharray: 2;
              }
            @include sp {
                left: -10%;
                width: 48%;
                padding: 10px;
                top: 80px;
            }
            @media screen and (max-width: 619px) {
                width: 43%;
            }
        }

        &__photo02 {
            @include position(absolute,15px,-15%,n,n);
            z-index: 4;
            max-width: 360px;
            max-height: 360px;
            padding: 20px;
            .st0 {
                fill: none;
                stroke: url(#SVGID_2_);
                stroke-width: 1;
                stroke-dasharray: 2;
              }
            @include sp {
                top: 15px;
                right: -10%;
                width: 40%;
                padding: 10px;
            }
        }

        &__photo03 {
            @include position(absolute,n,10%,-10%,n);
            z-index: 4;
            max-width: 343px;
            max-height: 343px;
            padding: 20px;
            .st0 {
                fill: none;
                stroke: url(#SVGID_3_);
                stroke-width: 1;
                stroke-dasharray: 2;
              }
            @include sp {
                top: auto;
                bottom: -45px;
                right: 50px;
                width: 38%;
                padding: 10px;
            }
        }

        &__content {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: calc((100% - 1240px) / 2);
            top: 15px;
            z-index: 4;
            width: 754px;
            height: 754px;
            margin-right: 85px;
            background: url(../images/index/pic_mv_line_img01.png) 0 0 no-repeat;
            @include sp {
                background: url(../images/index/pic_mv_line_img01_sp.png) 0 0/contain no-repeat;
                top: 10px;
                left: 50%;
                right: 0;
                width: 100%;
                height: 100%;
                max-width: 610px;
                max-height: 610px;
                margin-right: 0;
                transform: translateX(-50%);
                align-items: flex-end;
            }
        }

        &__content-inner {
            @include sp {
                width: 70%;
                margin-top: 10px;
            }
            @media screen and (max-width: 619px) {
                width: 70%;
                margin-top: 35px;
            }
        }

        &__ti  {
            padding: 0 20px;
            @include sp {
                text-align: right;
            }
            img {
                width: inherit;
                max-width: 100%;
                height: auto;
            }
        }

        &__txt {
            margin-top: 30px;
            padding: 0 20px;
            @include sp {
                margin-top: 20px;
                text-align: right;
            }
            img {
                width: inherit;
                max-width: 100%;
                height: auto;
            }
        }
        
        &__info {
            position: absolute;
            max-width: 1240px;
            left: calc((100% - 1240px) / 2);
            bottom: 100px;
            z-index: 10;
            @media screen and (max-width: 1320px) {
                max-width: calc(100% - 70px);
                left: 35px;
            }
            @include sp {
                position: absolute;
                max-width: inherit;
                width: calc(100% - 40px);
                left: 20px;
                bottom: 0;
            }
        }


        .info__link {
            display: block;
            color: $corona_color;
            &:hover {
                .info__txt {
                    opacity: $opacity;
                }
            }
        }

        .info__list {
            @include flex(center,center,wrap);
            justify-content: flex-start;
            border-radius: 33px;
            background-color: #727272;
            padding: 2px 30px 2px 15px;
            color: $white;
            @include sp {
                justify-content: center;
                border-radius: 45px;
                padding: 10px 20px;
            }
        }

        .info__ti {
            margin: 7px 10px 7px 0;
            border-radius: 14px;
            background-color: #b20610;
            padding: 5px 10px;
            font-size: 1.3rem;
            color: $white;
            text-align: center;
            transition: all .3s ease;
            @include sp {
                @include position(absolute,-15px,auto,n,auto);
                margin: 0;
                padding: 5px 10px;
                border-radius: 18px;
                font-size: 1.1rem;
            }
        }

        .info__txt {
            font-size: 1.5rem;
            transition: all .3s ease;
            @include sp {
                margin-top: 10px;
                font-size: 1.3rem;
            }
        }
    }


    //導入事例
    .case {
        position: relative;
        z-index: 3;
        @include sp {
            margin-top: 45px;
        }
        &__inner {
            .sec__ttl {
                text-align: center;
            }

            .sec__h2 {
                display: inline-block;
                padding-bottom: 5px;
                font-size: 3rem;
                @include sp {
                    font-size: 2.1rem;
                }
                &::before {
                    height: 2px;
                }
                & > span {
                    position: relative;
                    padding: 0 14px;
                    &::before,&::after {
                        content: "";
                        @include position(absolute,n,n,-16px,n);
                        width: 12px;
                        height: 12px;
                        border-radius: 10px;
                        background: $white;
                        @media all and (-ms-high-contrast: none) {
                          bottom: -10px;
                        }
                        @include sp {
                            @include position(absolute,n,n,-14px,n);
                        }
                    }

                    &::before {
                        left: -10px;
                        background-color: $black02;
                    }

                    &::after {
                        right: -10px;
                        background-color: $black02;
                    }
                }
            }
            .coming-soon{
                .case__link{
                    height: 100%;
                }
                .case__content{
                    display: flex;
                    height: 100%;
                    align-items: center;
                }
                .case__ti{
                    margin: 0 auto;
                }
                
            }
        }
        &__row {
            // min-width: 1100px;
            min-width: 1024px;
            max-width: 1280px;
            margin: 40px auto 80px;
            padding: 0 20px;
            @include sp {
                min-width: inherit;
                max-width: inherit;
                padding: 0;
            }
            .slick-list {
                padding: 5px 0 15px;
                @include sp {
                    padding-top: 15px !important;
                    padding-bottom: 15px !important;
                }
            }

            .slick-arrow {
                @include position(absolute,50%,n,n,n);
                z-index: 10;
                width: 39px;
                height: 39px;
                margin-top: -20px;
                padding-top: 40px;
                overflow: hidden;
                border-radius: 50%;
                background-color: $black02;
                transition: all .3s ease;
                &.slick-prev {
                    left: -20px;
                    &::before {
                        left: calc(50% - 4px);
                        transform: rotate(225deg);
                    }
                }
                &.slick-next {
                    right: -20px;
                    &::before {
                        left: calc(50% - 8px);
                        transform: rotate(45deg);
                    }
                }
                &::before {
                    position: absolute;
                    top:calc(50% - 6px);
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-top: solid 2px $white;
                    border-right: solid 2px $white;
                    transition: all .3s ease;
                }
                &:hover {
                    background-color: $green;
                    transition: all .3s ease;
                    &::before {
                        border-top-color: $white;
                        border-right-color: $white;
                    }
                }
            }
        }

        &__column {
            margin: 0 15px;
            box-shadow: 11px 11px 7px rgba(0,0,0,.05);
            background-color: $gray02;
            @include sp {
                margin: 0 15px;
            }
        }

        &__link {
            display: block;
            position: relative;
            top: 0;
            &:hover {
                opacity: $opacity;
            }
        }

        &__pic {
            text-align: center;
        }

        &__content {
            padding: 20px 20px 50px;
        }

        &__stats {
            display: flex;
            flex-wrap: wrap;
        }

        &__tag {
            display: flex;
            padding: 5px 10px;
            font-size: 1.2rem;
            border-radius: 4px;
            color: $black02;
            background: $white;
            align-items: center;
            text-align: center;
            margin-bottom: 5px;
            margin-right: 5px;
            word-break: keep-all;
            
        }

        &__ti {
            margin-top: 15px;
            font-size: 1.7rem;
        }

        &__txt {
            margin-top: 18px;
            font-size: 1.5rem;
            line-height: 1.6;
        }

        //導入企業一覧
        &__company {
            position: relative;
            z-index: 3;
            margin-top: 80px;
            @include sp {
                margin-top: 40px;
            }
            .sec__h3 {
                font-size: 2rem;
                text-align: center;
                @include sp {
                    font-size: 1.7rem;
                }
            }
        }

        &__slide {
            margin-top: 40px;
            @include sp {
                margin-top: 30px;
            }
            .slick-track {
                @include flex(center,center,wrap);
            }
        }

        &__company_column {
            float: none;
            margin: 0 25px;
            img {
                width: auto;
            }
        }

        &__more-block {
            margin-top: 80px;
            text-align: center;
            @include sp {
                margin-top: 40px;
            }
            & > .more-btn {
                display: inline-block;
                position: relative;
                top: 0;
                width: 100%;
                max-width: 278px;
                box-shadow: 11px 11px 7px rgba(0,0,0,.08);
                background-color: $black02;
                border-radius: 10px;
                padding: 20px;
                color: $white;
                font-size: 1.7rem;
                text-align: center;
                @include sp {
                    max-width: 228px;
                    padding: 17px 20px;
                    font-size: 1.5rem;
                }
                &::after {
                    @include position(absolute,50%,20px,n,n);
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    margin-top: -6px;
                    border-top: solid 2px $white;
                    border-right: solid 2px $white;
                    transform: rotate(45deg);
                }
                &:hover {
                    opacity: $opacity;
                    //top: -3px;
                }
            }
        }
    }

    //私達の強み
    .our {
        position: relative;
        z-index: 5;
        background: $yellow;
        padding: 100px 0 130px;
        @include sp {
            padding: 40px 20px 70px;
        }
        &__inner {
            min-width: 1000px;
            max-width: 1000px;
            margin: 0 auto;
            padding: 0 20px;
            @include sp {
                min-width: inherit;
                max-width: inherit;
                padding: 0;
            }
        } 
        .sec__ttl {
            text-align: center;
        }

        .sec__h2 {
            display: inline-block;
            padding-bottom: 5px;
            font-size: 3rem;
            color: $black02;
            @include sp {
                font-size: 2.1rem;
            }
            &::before {
                height: 2px;
                background: $black02;
            }
            & > span {
                position: relative;
                padding: 0 14px;
                &::before,&::after {
                    content: "";
                    @include position(absolute,n,n,-16px,n);
                    width: 12px;
                    height: 12px;
                    border-radius: 10px;
                    background: $white;
                    @media all and (-ms-high-contrast: none) {
                        bottom: -10px;
                    }
                    @include sp {
                        @include position(absolute,n,n,-14px,n);
                    }
                }

                &::before {
                    left: -10px;
                    background-color: $black02;
                }

                &::after {
                    right: -10px;
                    background-color: $black02;
                }
            }
        }

        &__row {
            display: flex;
            justify-content: space-between;
            margin-top: 75px;
            @include sp {
                flex-flow: column;
                margin-top: 55px;
            }
        }

        &__column {
            @include sp {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: inherit;
                &:nth-child(even) {
                    flex-flow: row-reverse;
                    .our__number,.our__caption {
                        text-align: right;
                    }
                }

                &:nth-child(n+2) {
                    margin-top: 60px;
                }
            }
        }

        &__detalis {
            width: 65%;
            @include tabpc {
                margin: 0 auto;
            }
        }

        &__number {
            font-size: 5rem;
            font-weight: 500;
            font-family: $font-famiily-jost;
            font-style: italic;
            color: $black02;
            @include sp {
                font-size: 4.5rem;
                text-align: left;
            }
        }

        &__pic {
            margin-top: 30px;
            text-align: center;
            @include sp {
                width: 35%;
                margin-top: 0;
            }
            
            img {
                max-width: 186px;
                height: auto;
                @include sp {
                    max-width: inherit;
                }
            }
        }

        &__caption {
            margin-top: 40px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: $black02;
            @include sp {
                margin-top: 5px;
                font-size: 1.7rem;
                text-align: left;
            }
        }
    }

    //サービス
    .sec-level2 {
        position: relative;
        z-index: 4;
        @include sp {
            &::before {
                @include position(absolute,0,n,n,0);
                z-index: -1;
                content: "";
                width: 100%;
                height: 1128px;
                background: url(../images/index/bg_service_img01.png) 0 0 no-repeat;
            }
        }
        .sec__ttl {
            padding-top: 50px;
            text-align: center;
            @include sp {
                font-size: 2.1rem;
            }
        }

        .sec__h2 {
            display: inline-block;
            padding-bottom: 5px;
            font-size: 3rem;
            @include sp {
                font-size: 2.1rem;
            }
            &::before {
                height: 2px;
            }
            & > span {
                position: relative;
                padding: 0 14px;
                &::before,&::after {
                    content: "";
                    @include position(absolute,n,n,-16px,n);
                    width: 12px;
                    height: 12px;
                    border-radius: 10px;
                    background: $white;
                    @media all and (-ms-high-contrast: none) {
                      bottom: -10px;
                    }
                    @include sp {
                        @include position(absolute,n,n,-14px,n);
                    }
                }

                &::before {
                    left: -10px;
                    background-color: $black02;
                }

                &::after {
                    right: -10px;
                    background-color: $black02;
                }
            }
        }

        .sec__lead {
            margin-top: 40px;
            text-align: center;
            font-size: 1.7rem;
            @include sp {
                margin-top: 30px;
                font-size: 1.3rem;
            }
        }

        &__inner {
            margin-top: 60px;
            margin-bottom: 60px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include sp {
                margin-top: 30px;
                margin-bottom: 30px;
            }
            .u-card03 {
                background-color: transparent;
                &__link {
                    .u-button02 {
                        &:hover {
                            background-color: $green;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .service__support {
        position: relative;
        z-index: 4;
        .inner {
            @include sp {
                padding: 0 20px;
            } 
        }
        .u-card01 {
            @include sp {
                &:last-child {
                    margin-top: 60px;
                    margin-bottom: 0;
                }
            }           
            &__detail {
                @include sp {
                    padding-bottom: 5px;
                }
            }

            &__link {
                .u-button02 {
                    &:hover {
                        background-color: $green;
                        color: $white;
                    }
                }
            }

            &__more-block {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 87%;
                @include sp {
                    height: inherit;
                }
            }

            &__more-link {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 1;
                width: 346px;
                height: 346px;
                box-shadow: 11px 11px 7px rgba(0,0,0,.08);
                border-radius: 50%;
                background-color: $black02;
                font-size: 1.7rem;
                font-weight: bold;
                color: $white;
                @include sp {
                    width: 200px;
                    height: 200px;
                    font-size: 1.3rem;
                }
                &::before {
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    margin-top: -6px;
                    border-top: solid 2px $white;
                    border-right: solid 2px $white;
                    transform: rotate(45deg);
                    @include sp {
                        right: 20px;
                    }
                }
                &:hover {
                    background: #727272;
                    opacity: 1;
                }
            }
        }
    }

    //お知らせ
    .information {
        position: relative;
        z-index: 4;
        padding: 80px 20px 90px;
        background-color: $white;
        @include sp {
            padding: 40px 20px 30px;
        }
        &__inner {
            min-width: 1140px;
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 20px;
            @include sp {
                min-width: inherit;
                max-width: inherit;
                padding: 0;
            }
        }

        &__tabs {
            display: flex;
            border-bottom: 2px solid $yellow;
            @include sp {
                border-bottom: 2px solid $yellow;
            }
        }

        &__tab {
            width: 120px;
            &.is-active {
                a {
                    background-color: $yellow;
                    &:hover {
                        background-color: $yellow;
                    }
                }
            }
            @include sp {
                width: 50%;
            }
            a {
                display: block;
                background-color: $border_gray;
                padding: 10px;
                font-size: 1.7rem;
                color: $black02;
                font-weight: bold;
                text-align: center;
                @include sp {
                    font-size: 1.3rem;
                }
                &:hover {
                    background-color: $drak_gray2;
                }
            }
        }

        .tab__column {
            display: none;
            &.tab__show {
                display: block;
                animation-name: fade-in;
                animation-duration: .7s; //遷移時間
            }

            @keyframes fade-in {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
        }

        &__list {
            width: 100%;
            li {
                display: flex;
                align-items: center;
                padding: 30px 0;
                border-bottom: 1px solid $dark_gray;
                @include sp {
                    display: block;
                    padding: 15px 0;
                }
            }
            a {
                font-size: 1.5rem;
                @include sp {
                    display: block;
                    margin-top: 7px;
                    font-size: 1.3rem;
                }
            }
        }

        &__time {
            margin-right: 50px;
            font-size: 1.5rem;
            font-family: $font-famiily-jost;
            @include sp {
                margin-right: 0;
                font-size: 1.3rem;
            }
        }

        &__more {
            text-align: center;
            a {
                display: block;
                position: relative;
                width: 147px;
                height: 0;
                background-color: $gray02;
                border-radius: 0 0 10px 10px;
                overflow: hidden;
                margin: 0 auto;
                padding-top: 34px;
                color: $white;
                @include sp {
                    width: 130px;
                    padding-top: 30px;
                }
                &:hover {
                    background-color: #ccc;
                }
                &::before {
                    @include position(absolute,50%,n,n,50%);
                    content: "";
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin: -11px 0 0 -7px;
                    border-top: solid 2px $black02;
                    border-right: solid 2px $black02;
                    transform: rotate(135deg);
                }
            }
        }
    }
}