.sitepolicy {
    &__inner {
        @include sp {
            padding: 0 20px 50px;
        }
        @include tabpc {
            // min-width: 1100px;
            min-width: 1024px;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 20px 100px;
        }
    }
    .u-heading02 {
        @include sp {
            margin-top: 40px;
            margin-bottom: 25px;
        }
        @include tabpc {
            margin-top: 80px;
            margin-bottom: 50px;
        }
    }
    &__txt {
        p {
            @include sp {
                margin: 0 0 12px 0;
            }
            @include tabpc {
                margin: 0 0 24px 0;
            }
        }
    }
    &__big {
        font-weight: bold;
        @include sp {
            font-size: 1.6rem;
        }
        @include tabpc {
            font-size: 1.8rem;
        }
    }
    &__notes {
        padding-left: 10px;
        text-indent: -10px;
    }
    &__list {
        @include sp {
            margin: 0 0 15px 0;
        }
        @include tabpc {
            margin: 0 0 30px 0;
        }
    }
    // ------ common
    .u-inline {
        display: inline-block;
    }
    .u-tar {
        text-align: right;
    }
}