.outline {
    .chart {
        &__inner {
            @include sp {
                margin: 0 auto 65px;
            }
            @include tabpc {
                margin: 0 auto 150px;
            }
        }
        &__img {
            margin-bottom: 20px;
            text-align: center;
        }
    }
    .officer {
        @include tabpc {
            display: flex;
        }
        &__position {
            @include tabpc {
                width: 130px;
            }
        }
        &__name {
            @include sp {
                margin-left: 16px;
            }
        }
    }
    .u-tbl01 {
        @include sp {
           margin: 30px 0 40px;
        }
        @include tabpc {
            margin: 0 0 80px;
        }
        &__link {
            @include sp {
                margin-top: 10px;
            }
            @include tabpc {
                margin-top: 10px;
            }
            .u-link03 {
                @include sp {
                    margin: 0 auto;
                }
            }
        }
        &__inner {
            +.hub {
                .u-tbl01__link {
                    @include sp {
                        margin: 10px 0;
                    }
                    @include tabpc {
                        margin: 10px 0;
                    }
                }
            }
        }
    }
    .u-heading02 {
        @include sp {
            margin-bottom: 25px;
        }
        @include tabpc {
            margin-bottom: 50px;
        }
    }
}

//----------common
.u-tbl01 {
    &__inner {
        @include tabpc {
            display: flex;
        }
        &:last-of-type {
            .u-tbl01__ttl {
                @include tabpc {
                    border-bottom: 1px solid $yellow;
                }
            } 
            .u-tbl01__txt {
                @include sp {
                    border-bottom: 1px solid $yellow;
                }
                @include tabpc {
                    border-bottom: 1px solid $dark_gray;
                }
            } 
        }
    }
    &__ttl {
        padding: 15px 10px 15px 0;
        margin-right: 2px;
        border-top: 1px solid $yellow;
        font-weight: bold;
        @include sp {
            width: 100%;
        }
        @include tabpc {
            width: 30%;
        }
    }
    &__txt {
        padding: 15px 10px 15px 0;
        line-height: 1.8;
        @include sp {
            width: 100%;
            border-top: 1px solid #e2e4e9; //$dark_grayの少し薄め 必要に応じて変数設定
        }
        @include tabpc {
            width: 70%;
            border-top: 1px solid $dark_gray;
        }
        a[href^="tel:"] {
            display: inline-block;
        }
    }
}
.u-link01 {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        display: inline-block;
        background-color: $black;
        border-radius: 50%;
        @include sp {
            top: calc(50% - 9px);
            width: 18px;
            height: 18px;
        }
        @include tabpc {
            top: calc(50% - 10px);
            width: 20px;
            height: 20px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        left: 7px;
        display: inline-block;
        border-style: solid;
        border-color: transparent transparent transparent $white;
        @include sp {
            top: calc(50% - 3px);
            border-width: 3px 0px 3px 6px;
        }
        @include tabpc {
            top: calc(50% - 4px);
            border-width: 4px 0px 4px 7px;
        }
    }
}
.u-link02 {
    color: $dark_green;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
.u-link03 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $black;
    color: $black;
    font-weight: bold;
  @include sp {
    width: 50%;
    height: 38px;
    border-radius: 5px;
    font-size: 1.2rem;
  }
  @include tabpc {
    max-width: 120px;
    width: 50%;
    height: 44px;
    border-radius: 8px;
    font-size: 1.5rem;
  }
  &:hover {
    border: solid 1px $black;
    color: $white;
    background-color: $black;
  }
}
.u-tag01 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $white;
    border: 1px solid $black;
    color: $black;
    font-weight: bold;
    @include sp {
        padding: 4px 8px;
        font-size: 1.1rem;
        max-width: 150px;
    }
    @include tabpc {
        padding: 5px 10px;
        font-size: 1.2rem;
        max-width: 150px;
    }
}
.u-tag02 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $black;
    border: 1px solid $black;
    color: $white;
    font-weight: bold;
    @include sp {
        padding: 4px 8px;
        font-size: 1.1rem;
        max-width: 150px;
    }
    @include tabpc {
        padding: 5px 10px;
        font-size: 1.2rem;
        max-width: 150px;
    }
}