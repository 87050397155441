//-------------utility
$yellow: #fecb0a;
$green: #0aad8a;
//タイトル
.u-heading01 {
  @include sp {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  @include tabpc {
    font-size: 3rem;
    line-height: 4.2rem;
  }
}
.u-heading02 {
  text-align: center;
  @include sp {
    margin-bottom: 40px;
  }
  @include tabpc {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &__inner {
    display: inline-block;
    padding-bottom: 5px;
    @include position();
    letter-spacing: 0;
    &:before {
      content: "";
      @include position(absolute, n, n, 0, n);
      width: 100%;
      height: 4px;
      background: $black02;
    }

    @include sp {
      font-size: 2.1rem;
      line-height: 2.2rem;
    }
    @include tabpc {
      font-size: 3rem;
      line-height: 3.6rem;
    }

    &::before {
      height: 2px;
    }
    & > span {
      position: relative;
      padding: 0 10px;
      display: inline-block;
      &::before,
      &::after {
        content: "";
        @include position(absolute, n, n, -10px, n);
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: $white;
        @include sp {
          @include position(absolute, n, n, -9px, n);
        }
      }

      &::before {
        background-color: $black02;
        @include sp {
          left: -8px;
        }
        @include tabpc {
          left: -10px;
        }
      }

      &::after {
        background-color: $black02;
        @include sp {
          right: -8px;
        }
        @include tabpc {
          right: -10px;
        }
      }
    }
  }
}

//カード
.u-card01 {
  @include sp {
    max-width: 436px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 769px) and (max-width: 1260px) {
    width: 520px !important;
    //height: 357px;
  }
  @include pc {
    width: 610px;
  }
  &.is-more-link {
    margin: 0 0 0 auto;
  }
  a {
    &:hover {
      opacity: .7;
    }
  }
  &__pic {
    @include sp {
      width: 86%;
    }
    @include tab {
      max-width: 565px;
      height: 407px;
      width: 94%;
    }
    @include pc {
      max-width: 565px;
      height: 407px;
      width: 94%;
    }
    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &__detail {
    position: relative;
    margin: 0 0 0 auto;
    background: #ffffff;
    @include sp {
      margin-top: -67px;
      width: 96%;
      padding: 15px 15px 0;
      border-radius: 9px;
      box-shadow: 0px 9px 5px 0px rgba(0, 0, 0, 0.05);
    }
    @include tab {
      top: -92px;
      width: 529px;
      padding: 30px;
      border-radius: 12px;
      box-shadow: 0px 11px 7px 0px rgba(0, 0, 0, 0.05);
    }
    @include pc {
      top: -92px;
      width: 529px;
      padding: 30px;
      border-radius: 12px;
      box-shadow: 0px 11px 7px 0px rgba(0, 0, 0, 0.05);
    }
    @media screen and (min-width: 769px) and (max-width: 1260px) {
      width: 479px;
      padding: 20px;
    }
  }
  &__ttl {
    color: $black02;
    line-height: 1;
    font-size: 1.17em;
    @include sp {
      margin-bottom: 10px;
    }
    @include tab {
      margin-bottom: 25px;
    }
    @include pc {
      margin-bottom: 25px;
    }
  }
  &__text {
    @include sp {
      margin-bottom: 1.3rem;
      font-size: 1.3rem;
      line-height: 1.9rem;
    }
    @include tab {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
    @include pc {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
  }
  &__more {
    text-align: right;
    @include sp {
      padding-top: 10px;
      padding-bottom: 16px;
    }
    @include tab {
      padding-top: 10px;
      padding-bottom: 25px;
    }
    @include pc {
      padding-top: 10px;
      padding-bottom: 25px;
    }
  }
  &__link {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    .btn {
      margin-bottom: 10px;
    }
  }
  


  &__more-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 87%;
    @include sp {
        height: inherit;
    }
  }

  &__more-link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 346px;
    height: 346px;
    box-shadow: 11px 11px 7px rgba(0,0,0,.08);
    border-radius: 50%;
    background-color: $black02;
    font-size: 1.7rem;
    font-weight: bold;
    @include sp {
      width: 200px;
      height: 200px;
      font-size: 1.3rem;
    }
    color: $white;
    &::before {
      position: absolute;
      top: 50%;
      right: 30px;
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      border-top: solid 2px $white;
      border-right: solid 2px $white;
      transform: rotate(45deg);
      @include sp {
          right: 20px;
      }
    }

}




}
.u-card02 {
  background: $gray02;
  @include sp {
    width: 200px;
    box-shadow: 0px 9px 5px 0px rgba(0, 0, 0, 0.05);
  }
  @include tabpc {
    width: 289px;
    box-shadow: 0px 11px 7px 0px rgba(0, 0, 0, 0.05);
  }
  &__detail {
    height: 100%;
    @include sp {
      padding: 15px 15px 20px;
    }
    @include tabpc {
      padding: 20px 20px 30px;
    }
  }
  &__link{
    height: 100%;
  }
  &__pic {
    overflow: hidden;
    @include sp {
      height: 141px;
    }
    @include tabpc {
      height: 204px;
    }
  }
  &__ttl {
    font-weight: bold;
    @include sp {
      padding: 8px 0 10px;
      font-size: 1.5rem;
    }
    @include tabpc {
      padding: 15px 0;
      font-size: 1.7rem;
    }
  }
  &__text {
    letter-spacing: -0.01em;
    @include sp {
      font-size: 1.3rem;
      line-height: 1.9rem;
    }
    @include tabpc {
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
  }
  a {
    &:hover {
      opacity: $opacity;
  }
  }
}
.coming-soon{
  .u-card02__pic{
      display: none;
  }
  .u-card02__ttl{
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
  }
  .case__ti{
      margin: 0 auto;
  }
  
}
.u-card03 {
  background: #fff;
  a {
    &:hover {
      opacity: .7;
    }
  }
  @include sp {
    max-width: 650px;
    //width: calc(100% - 40px);
    //padding: 0 20px;
  }
  @include tabpc {
    max-width: 585px;
    width: 48%;
  }
  &__detail {
    @include sp {
      padding: 8px 0 20px;
    }
    @include tabpc {
      padding: 34px 0 5px;
    }
  }
  &__pic {
    overflow: hidden;
    @include sp {
    }
    @include tabpc {
      height: 407px;
    }
    img {
      max-width: inherit !important;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  &__ttl {
    position: relative;
    font-weight: bold;
    color: $black02;
    @include sp {
      padding: 0 0 7px;
      margin-bottom: 15px;
      font-size: 1.5rem;
    }
    @include tabpc {
      padding: 0 0 5px;
      min-height: 38px;
      margin-bottom: 20px;
      font-size: 2.2rem;
      line-height: 1;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from($black02),
        to($black02)
      );
      background: -webkit-linear-gradient(left, $black02, $black02);
      background: linear-gradient(to right, $black02, $black02);
    }
  }
  &__more {
    @include sp {
      padding: 4px 0 10px;
    }
    @include tabpc {
      padding: 2px 0 32px;
    }
  }
  &__link {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    .btn {
      margin-bottom: 10px;
      @include sp {
      }
      @include tabpc {
        width: 32%;
      }
    }
  }
  &__text {
    letter-spacing: -0.01em;
    @include sp {
      margin-bottom: 1.3rem;
      font-size: 1.3rem;
      line-height: 1.9rem;
    }
    @include tabpc {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
  }
}

//アイコンなど
.u-seal01 {
  display: inline-block;
  background: $white;
  color: $black02;
  margin-bottom: 5px;
  margin-right: 5px;
  //letter-spacing: -0.1em;
  @include sp {
    padding: 0 7px;
    height: 18px;
    font-size: 1.1rem;
    line-height: 1.8rem;
    border-radius: 2px;
  }
  @include tabpc {
    height: 23px;
    padding: 0 11px;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 23px;
  }
}

//ボタン
.u-button01 {
  display: block;
  position: relative;
  color: $black02;
  line-height: 1;
  font-weight: bold;
  text-align: right;
  @include sptab {
    padding-right: 35px;
    font-size: 1.3rem;
  }
  @include tabpc {
    padding-right: 37px;
    font-size: 1.4rem;
  }
  &:before,
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    height: 1px;
    display: block;
    background: $black02;
  }
  &:before {
    @include sp {
      width: 36px;
    }
    @include tabpc {
      width: 37px;
    }
  }
  &:after {
    transform: rotate(45deg);
    width: 12px;
    bottom: 5px;
  }
  &:hover {
    &:before,
    &:after {

    }
  }
}
.u-button02 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px $black02;
  color: $black02;
  @include sp {
    width: 46%;
    min-width: 137px;
    height: 27px;
    border-radius: 5px;
    font-size: 1.1rem;
    letter-spacing: .1rem;
  }
  @include tabpc {
    max-width: 227px;
    width: 48%;
    height: 44px;
    border-radius: 8px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &:hover {
    background-color: $black02 !important;
    color: $white;
  }
  //&.is-wide {
  //  width: 100%;
  //  max-width: inherit;
  //  @include sp {
  //  }
  //  @include tabpc {
  //  }
  //}
}
.u-button03 {
  display: inline-block;
  border: solid 1px $black;
  color: $black;
  @include sp {
    height: 57px;
    padding: 0 40px;
    border-radius: 8px;
    font-size: 1.5rem;
    line-height: 5.7rem;
  }
  @include tabpc {
    height: 60px;
    padding: 0 28px;
    border-radius: 8px;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 6rem;
  }
  &:hover {
    background-color: $black02 !important;
    color: $white;
  }
}
.u-button05 {
  display: block;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  padding: 16px 10px;
  background: $yellow;
  box-shadow: 0 5px 0 0 $yellow_shadow;
  border-radius: 10px;
  >span {
      @include position();
      &:before,&:after {
          content: "";
          @include position(absolute,20%,-14px,n,n);
          width: 2px;height: 14px;
          background: $default;
      }
      &:before {
          margin: 4px 0 0 0;
          transform: rotate(50deg);
      }
      &:after {
          margin: -4px 0 0 0;
          transform: rotate(-50deg);
      }    
  }
  &:hover {
      color: $default;
      opacity: .7;
  }
}

 