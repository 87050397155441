// =========================================================
// MIXIN
// =========================================================

// ブレークポイント設定のmixin
// sp, tab, pcではcssの上書きはなるべくしないようにする（それぞれのmixinで中身を書き分ける）
// 例外としてwideのpc幅の時は最後に設定し、上書きをする
@mixin sp {
  @media screen and (max-width: $sp + px){
    @content;
  }
}
@mixin sptab {
  @media screen and (max-width: $tab + px){
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: ($sp + 1) + px) and (max-width: $tab + px){
    @content;
  }
}
@mixin tabpc {
  @media screen and (min-width: ($sp + 1) + px){
    @content;
  }
}
@mixin tabwide {
  @media screen and (min-width: ($sp + 1) + px) and (max-width: $pc + px){
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: ($tab + 1) + px){
    @content;
  }
}
@mixin pc2 {
  @media screen and (min-width: ($tab + 1) + px) and (max-width: $pc + px){
    @content;
  }
}
@mixin wide {
  @media screen and (min-width: ($pc + 1) + px){
    @content;
  }
}

// inner以下
@mixin inner {
  @media screen and (min-width: ($sp + 1) + px) and (max-width: ($inner + px) ){
    @content;
  }
}

//spフォントのvwに対応したmixin
@mixin fzvw($fz, $vw:$compWidth) {
    font-size: (($fz / $vw) * 100) + vw;
}


// --
// 使い方
// @mixin flex(0, center, 0, 0, 1);
// --
// justify-content水平方向揃え（第一引数）
// align-items垂直方向揃え（第二引数）
// flex-wrap子要素の折り返し設定（第三引数）
// align-content複数行設定（第四引数）
// 縦並び（第五引数）

@mixin flex($justify:0, $align:0, $wrap:0, $wrap_align:0, $column:0) {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  // justify-content水平方向揃え（第一引数）
  @if $justify == start { // 左揃え
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
  } @else if $justify == end { // 右揃え
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
  } @else if $justify == center { // 中央揃え
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
  } @else if $justify == between { // 両端配置
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
  } @else if $justify == around { // 均等配置
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
  }

  // align-items垂直方向揃え（第二引数）
  @if $align == start { // 上揃え
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
  } @else if $align == end { // 下揃え
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
  } @else if $align == center { // 中央揃え
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
  } @else if $align == baseline { // ベースライン
      -webkit-align-items: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
  } @else if $align == stretch { // 高さ揃え
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
  }

  // flex-wrap子要素の折り返し設定（第三引数）
  @if $wrap == nowrap { // 折り返ししない
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  } @else if $wrap == wrap { // 上から下
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  } @else if $wrap == reverse { // 下から上
      -webkit-flex-wrap: wrap-reverse;
      -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  }

  // align-content複数行設定（第四引数）
  @if $wrap_align == start { // 上揃え
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
  } @else if $wrap_align == end { // 下揃え
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
  } @else if $wrap_align == center { // 中央揃え
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
  } @else if $wrap_align == between { // 両端配置
      -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
      align-content: space-between;
  } @else if $wrap_align == around { // 均等配置
      -webkit-align-content: space-around;
      -ms-flex-line-pack: distribute;
      align-content: space-around;
  } @else if $wrap_align == stretch { // 高さ揃え
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
  }

  // 縦並び（第五引数）
  @if $column == 1 {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }
}


// --
// 使い方
// @mixin position(absolute, top, right, bottom, left);
// --
@mixin position($def: relative, $t: auto, $r: auto, $b: auto, $l: auto){
  @if $def == absolute {
      position: absolute;
  } @else if $def == fixed {
      position: fixed;
  } @else {
      position: relative;
  }
  @if $t != auto and $t != n { top: $t; }
  @if $r != auto and $r != n { right: $r };
  @if $b != auto and $b != n { bottom: $b };
  @if $l != auto and $l != n { left: $l };
}
