.sitemap {
    &__inner {
        @include sp {
            padding: 0 20px 50px;
        }
        @include tabpc {
            // min-width: 1100px;
            min-width: 1024px;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 20px 100px;
        }
    }
    &__ttl {
        position: relative;
        @include sp {
            margin-top: 30px;
            padding-bottom: 5px;
            font-size: 1.8rem;
        }
        @include tabpc {
            margin-top: 30px;
            padding-bottom: 10px;
            font-size: 2rem;
        }
        &:before {
            position: absolute;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            content: "";
            display: block;
            border-bottom: 2px solid $yellow;
            width: 228px;
        }
        &:after {
            position: absolute;
            top: auto;
            right: 0;
            bottom: 0;
            left: 230px;
            content: "";
            display: block;
            border-bottom: 2px solid $dark_gray;
        }
        a {
            width: 100%;
        }
    }
    &__wrap {
        @include tabpc {
            display: flex;
            justify-content: space-between;
        }
    }
    &__block {
        @include tabpc {
            width: calc((100% - 50px)/ 2);
        }
    }
    &__list {
        @include sp {
            margin-top: 10px;
        }
        @include tabpc {
            margin-top: 20px;
        }
        &02 {
            @include sp {
                margin-left: 30px;
            }
            @include tabpc {
                margin-left: 30px;
            }
        }
        &03 {
            @include sp {
                margin-left: 60px;
            }
            @include tabpc {
                margin-left: 60px;
            }
        }
    }
    &__item {
        @include sp {
            font-size: 1.6rem;
            line-height: 1.8;
        }
        @include tabpc {
            font-size: 1.8rem;
            line-height: 1.8;
        }
        a {
            width: 100%;
        }
    }
    .medium-cat-head{
        margin-top: 20px;
    }
    &__subarea {
        position: relative;
        @include sp {
            margin-top: 30px;
        }
        @include tabpc {
            margin-top: 60px;
            padding-bottom: 10px;
            font-size: 2rem;
        }
        &:before {
            position: absolute;
            right: auto;
            top: 0;
            left: 0;
            content: "";
            display: block;
            border-bottom: 2px solid $yellow;
            width: 228px;
        }
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            left: 230px;
            content: "";
            display: block;
            border-bottom: 2px solid $dark_gray;
        }        
    }
    &__sublist {
        @include sp {
            padding-top: 10px;
        }
        @include tabpc {
            display: flex;
            justify-content: flex-start;
            padding-top: 20px;
        }
    }
    &__subitem {
        @include tabpc {
            margin-right: 40px;
        }
    }
}