.message {
    .greeting {
        &__info {
            margin-bottom: 90px;
        }
        &__name {
            text-align: right;
        }
    }
    .happyClover {
        &__inner {
            max-width: 984px;
            width: 100%;
            @include sp {
                margin: 0 auto 65px;
            }
            @include tabpc {
                margin: 0 auto 150px;
            }
        }
        &__intro {
            position: relative;
            margin: 0 0 26px;
            padding: 35px 0;
            background: url(../images/company/bg_happyClover_01.png) no-repeat 0 0, url(../images/company/bg_happyClover_02.png) no-repeat 100% 100%;
            text-align: center;
            @include sp {
                padding: 30px 23px;
                background: url(../images/company/index_bg_happyClover_sp.png) no-repeat 0 0, url(../images/company/index_bg_happyClover_02_sp.png) no-repeat 100% 47px;
                background-size: 73px 81px, 49px 50px;
            }
            &:before {
                position: absolute;
                top: 0;
                right: 10px;
                bottom: 10px;
                left: 0;
                content: "";
                display: block;
                border: 2px solid #ff6633;
                border-radius: 10px;
                z-index: 1;
            }
            &:after {
                position: absolute;
                top: 10px;
                right: 0;
                bottom: 0;
                left: 10px;
                content: "";
                display: block;
                border: 2px solid #f5c51e;
                border-radius: 10px;
                z-index: 0;
            }
        }
        &__heading {
            position: relative;
            margin: 0 0 20px;
            padding: 0 0 10px;
            color: #009a44;
            font-size: 2.0rem;
            font-weight: bold;
            @include sp {
                margin-bottom: 15px;
                font-size: 1.4rem;
            }
            &:after {
                position: absolute;
                top: auto;
                right: auto;
                bottom: 0;
                left: 50%;
                content: "";
                display: block;
                margin: 0 0 0 -50px;
                border-bottom: 3px solid #f5c51e;
                width: 100px;
                @include sp {
                    margin-left: -25px;
                    border-bottom-width: 2px;
                    width: 50px;
                }
            }
        }
        &__ttl {
            margin: 0;
            color: #464646;
            font-size: 2.4rem;
            font-weight: bold;
            @include sp {
                margin: 0 0 10px;
                font-size: 1.6rem;
            }
            span {
                color: #ff6633;
                @include sp {
                    display: block;
                }
            }
            &+p {
                @include sp {
                    text-align: left;
                }
            }
        }
        &__text {
            @include sp {
                margin-bottom: 15px;
            }
            @include tabpc {
                margin-bottom: 30px;
            }
        }
    }

    .promise {
        &__item {
            position: relative;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            border: 3px solid;
            padding: 28px 30px;
            background: #ffffff;
            letter-spacing: 0;
            border-color: #cccccc;
            @include tabpc {
                width: calc(100% / 3 - 20px);
                height: 498px;
            }
            @include sp {
                display: block;
                margin: 0 0 20px;
                border-width: 2px;
                padding: 16px 20px;
                width: auto;
            }
            &.is-health {
                @include tabpc {
                    width: calc(100% / 4 - 20px);
                    height: 498px;
                }
            }
            &:before {
                position: absolute;
                top: -3px;
                right: auto;
                bottom: auto;
                left: 50%;
                content: "";
                display: block;
                margin: 0 0 0 -50px;
                border-top: 4px solid #f5c51e;
                width: 100px;
                @include sp {
                    position: absolute;
                    top: -2px;
                    right: auto;
                    bottom: auto;
                    left: 50%;
                    content: "";
                    display: block;
                    border-top-width: 3px;
                }
            }
            &:nth-of-type(1),&:nth-of-type(4) {
                .promise__heading {
                    >span {
                        &:before {
                            overflow: hidden;
                            display: block;
                            text-indent: 200%;
                            white-space: nowrap;
                            vertical-align: middle;
                            background-image: url(../images/company/icn_happyClover_01.png);
                            background-repeat: no-repeat;
                            width: 42px;
                            height: 44px;
                            background-position: 0 0;
                            @include sp {
                                width: 33px;
                                height: 35px;
                                background-position: 0 -159px;
                                -moz-background-size: 142px auto;
                                -o-background-size: 142px auto;
                                -webkit-background-size: 142px auto;
                                background-size: 142px auto;
                            }
                        }
                    }
                }
            }
            &:nth-of-type(2) {
                .promise__heading {
                    >span {
                        &:before {
                            overflow: hidden;
                            display: block;
                            text-indent: 200%;
                            white-space: nowrap;
                            vertical-align: middle;
                            background-image: url(../images/company/icn_happyClover_01.png);
                            background-repeat: no-repeat;
                            width: 42px;
                            height: 44px;
                            background-position: 0 -54px;
                        }
                    }
                }
            }
            &:nth-of-type(3) {
                .promise__heading {
                    >span {
                        &:before {
                            overflow: hidden;
                            display: block;
                            text-indent: 200%;
                            white-space: nowrap;
                            vertical-align: middle;
                            background-image: url(../images/company/icn_happyClover_01.png);
                            background-repeat: no-repeat;
                            width: 42px;
                            height: 44px;
                            background-position: 0 -186px;
                        }
                    }
                }
            }
            &:not(:last-of-type) {
                @include sp {
                    margin: 0 0 20px 0;
                }
                @include tabpc {
                    margin: 0 20px 0 0;
                }
            }
        }
        &__heading {
            margin: 0 -10px 20px;
            border-bottom: 2px solid #cccccc;
            padding: 0 0 15px;
            font-size: 2.0rem;
            font-weight: bold;
            text-align: center;
            @include sp {
                margin-bottom: 15px;
                border-bottom-width: 1px;
                padding-bottom: 12px;
                font-size: 1.4rem;
            }
            @include tabpc {
                height: 199px;
            }
            > span {
                position: relative;
                margin: 0 0 10px;
                display: inline-block;
                vertical-align: middle;
                font-size: 1.6rem;
                font-weight: normal;
                @include sp {
                    margin: 0 0 10px;
                    font-size: 1.4rem;
                }
                &:before {
                    margin: 0 auto 10px;
                    content: "";
                }
            }
        }
    }
}