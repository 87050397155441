.info{
  &__inner{
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0 200px;
  }
  &__date{
    font-size: 1.6rem;
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
  }
  &__dearSir{
    font-size: 1.6rem;
    width: 100%;
    margin-bottom: 30px;
  }
  &__company{
    font-size: 1.6rem;
    width: 100%;
    text-align: right;
    margin-bottom: 50px;
  }
  &__ttl{
    font-size: 2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
  &__content{
    font-size: 1.6rem;
    width: 100%;
    margin-bottom: 50px;
  }
  &__end{
    font-size: 1.6rem;
    width: 100%;
    text-align: right;
  }
}