@charset "UTF-8";

.footer {
    //background: $gray;
    padding: 0;
    @include sp {
        padding: 20px 0 0 0;
    }
    &__wrapper {
        // min-width: 1100px;
        min-width: 1024px;
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 10px;
        @include sp {
            min-width: auto;
            max-width: auto;
        }
        &--top {
            padding-top: 50px;
            border-top: 1px solid $black02;
        }
    }
    &__block {
        @include flex(between,start);
        @include sp {
            @include flex(between,start,wrap);
        }
    }
    &__col {
        width: calc(100% - 40px / 4);
        width: 24%;
        padding: 0 10px;
        >ul {
            font-size: 1.3rem;
            margin: 10px 0 0 0;
            >li {
                >a {
                    padding: 6px 0;

                }
            }
        }
        a {
            &:hover {
                opacity: $opacity;
            }
        }
        @include sp {
            width: 100%;
            padding: 0 20px;
            >ul {
                @include flex(start,center,wrap);
                flex-direction: column;
                font-size: 1.2rem;
                letter-spacing: 0;
                margin: 30px 0 0 0;
                >li {
                    width: 100%;
                    >a {
                        padding: 6px 0;
                    }
                }
            }
        }
    }
    &__logo {
        display: block;
        max-width: 256px;
    }
    &__ttl {
        @include position();
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0 0 8px 0;

        &:not(:first-of-type){
            margin: 40px 0 0 0;
        }
        @include sp {
            &:not(:first-of-type){
                margin: 20px 0 0 0;
            }
        }
        &--contact {
            &:before {
                content: "";
                @include position(absolute,n,n,0,n);
                width:100%;height: 1px;
                background: $dark_gray;
            }
        }
    }
    &__lowlist {
        @include position(relative,-10px,n,n,n);
        padding: 0 0 0 20px;
    }
    &__text {
        font-size: 1.2rem;
        line-height: 2.4rem;
    }
    &__lead {
        font-size: 1.7rem;
        letter-spacing: 0rem;
        margin: 20px 0 0;
        @include sp {
            font-size: 1.4rem;
            margin: 10px 0 0;
        }
    }
    &__tel {
        width: 100%;
        margin: 20px 0 0 0;
        >a{
            font-family: $font-famiily-jost;
            font-weight: 700;
            font-size: 3.6rem;
            pointer-events: none;
            @include sp {
                pointer-events: all;
                font-size: 3.4rem;
            }
        }
        @include sp {
            width: 80%;
            margin: 10px 0 0 0;    
        }
    }
    &__notice {
        font-size: 1.3rem;
        padding: 10px 0 40px 0;
        @include sp {
            padding: 10px 0 20px 0;
        }
    }
    &__mark { 
        justify-content: flex-start;
        >.footer__col {
            @include position();
            align-self: stretch;
            margin: 40px 10px 20px 0;
            padding: 0 0 28px 0;
            >p {
                @include position(absolute,n,0,0,0);
                font-size: 1.0rem;
                text-align: center;
            }
            &:first-of-type {
                width: 90px;
                >img {
                    display: block;
                    width: 50%;
                    margin: auto;
                }
            }
            &:last-of-type {
                width: 130px;
            }
        }
    }
    &__btn__contact {
        margin: 20px 0 0 0;
        >a {
            display: block;
            font-size: 1.7rem;
            font-weight: bold;
            text-align: center;
            padding: 16px 10px;
            background: $yellow;
            box-shadow: 0 5px 0 0 $yellow_shadow;
            border-radius: 10px;
            >span {
                @include position();
                &:before,&:after {
                    content: "";
                    @include position(absolute,20%,-14px,n,n);
                    width: 2px;height: 14px;
                    background: $default;
                }
                &:before {
                    margin: 4px 0 0 0;
                    transform: rotate(50deg);
                }
                &:after {
                    margin: -4px 0 0 0;
                    transform: rotate(-50deg);
                }    
            }
            &:hover {
                color: $white;
                background: $black02;
                box-shadow: 0 5px 0 0 $black;
                &>span:before,&>span:after {
                    background: $white;
                }
            }
        }
    }
    &__copy {
        font-size: 1.0rem;
        text-align: left;
        margin: 0;
        padding: 60px 0 40px;
    }
}