@charset "UTF-8";

.banner {
    &__wrapper {
        @include flex(center,center);
        // min-width: 1100px;
        min-width: 1024px;
        max-width: 1400px;
        margin: 50px auto;
        padding: 0 10px;
        @include sp {
            @include flex(center,center,wrap);
            min-width: auto;
            max-width: auto;
            width: 90%;
            margin: 20px auto;
        }
        >a {
            max-width: 258px;
            border: 1px solid $dark_gray;
            border-radius: 10px;
            margin: 0 1%;
            overflow: hidden;
            line-height: 0;
            &:hover {
                opacity: .7;
            }
            @include sp {
                max-width: none;
                border-radius: 5px;
                width: 45%;
                margin: 10px auto;
            }
        }
    }
    
}

.related {
    &__wrapper {
        width: 100%;
        background:rgba(0,0,0,.7);
    }
    &__block {
        @include flex(center,center);
        // min-width: 1100px;
        min-width: 1024px;
        max-width: 1400px;
        margin: 0 auto;
        @include sp {
            @include flex(center,center,wrap);
            min-width: auto;
            max-width: auto;
        }
    }
    &__col {
        @include position();
        align-self: stretch;
        width: 50%;
        color: $white;
        text-align:center;
        padding: 50px 0;
        @include sp {
            width: 100%;
            padding: 30px;
        }
        &:after{
            content: "";
            @include position(absolute,0,0,0,0);
            width: 100%;height: 100%;
            background:rgba(0,0,0,.7);
            z-index: 20;
            transition: all .3s ease;
        }
        &:before {
            content: "";
            @include position(absolute,0,0,0,0);
            width: 100%;height: 100%;
            z-index: 10;
        }
        &:first-of-type:before {
            background: url(../images/common/img_related_01.png) center center /cover no-repeat;
        }
        &:last-of-type:before {
            background: url(../images/common/img_related_02.png) center center /cover no-repeat;
        }
        &:hover {
            &:after{
                background:rgba(0,0,0,.5);
            }
        }
    }
    &__ttl {
        @include position();
        display: inline-block;
        font-size: 3.0rem;
        font-weight: 600;
        z-index: 30;
        @include sp {
            font-size: 2.1rem;
        }
        &:before,&:after {
            content: "";
            @include position(absolute,n,n,-10px,n);
            width: 12px;height: 12px;
            border-radius: 10px;
            background: $white;
            @media all and (-ms-high-contrast: none) {
                bottom: -14px;
            }
        }
        &:before {
            left: -10px;
        }
        &:after {
            right: -10px;
        }
        >span {
            @include position();
            padding: 0 14px;
            &:before {
                content: "";
                @include position(absolute,n,n,-10px,n);
                width: 100%; height: 2px;
                background: $white;
                margin: auto auto auto -14px;
            }
        }
    }
    &__text {
        @include position();
        z-index: 30;
        font-size: 1.7rem;
        line-height: 2.8rem;
        margin: 50px 0 0 0;
        @include sp {
            margin: 20px 0 0 0;
            font-size: 1.3rem;
        }
    }
    &__btn {
        @include position();
        z-index: 30;
        margin: 70px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
        >.btn {
            color: $white;
            font-size: 1.4rem;
            width: 120px;
            letter-spacing: 0;
            border: 1px solid $white;
            border-radius: 10px;
            margin: 0 auto;
            padding: 10px 20px;

            @include sp {
                font-size: 1.3rem;
            }    
        }
    }
}