@charset "UTF-8";

.contact {
    margin: 100px 0 0 0;
    letter-spacing: 0;
    @include sp {
        margin: 30px 0 0 0;
    }
    &__ttl {
        @include sp {
            letter-spacing: 0;
        }
    }
    &__tel {
        >a {
            max-width: 440px;
            margin: 30px 0 0 0;
        }
    }
    &__notice {
        font-size: 1.4rem;
        margin: 10px 0 0 0;
    }
    &__bg {
        &__01 {
            background: linear-gradient(to left, transparent 0%, transparent 70%, #fff 100%), url(../images/contact/img_contact_h1.png) center center /cover no-repeat;
        }
    }
    .sec {
        &__block {
            //@include flex(center,center);
            @include sp {
                @include flex(center,center,wrap);
            }       
        }
        &__col {
            //width: 50%;
            //align-self: stretch;
            margin: 50px 0 100px;
            //padding: 0 40px;
            //border-left: 1px solid $dark_gray;
            @include sp {
                width: 100%;    
                margin: 20px 0;
                padding: 0 20px;
                }       
        }
    }
    .btn {
        &__confirm {
            >input[type=submit] {
                width:400px;
                display: block;
                background:$yellow;
                text-align: center;
                font-size:1.8rem;
                padding: 20px;
                border-radius: 10px;
                margin: 50px auto 0;     
                @include sp {
                    width:80%;
                    margin: 30px auto 0;     
                }
            }
        }
        &__reset {
            >input[type=submit] {
                width: 80px;
                display: block;
                background: $gray;
                border: 1px solid $border_gray;
                font-size:1.4rem;
                text-align:center;
                border-radius: 5px;
                margin: 20px auto 0;     
                @include sp {
                    width:40%;
                    margin: 30px auto 0;     
                    padding:10px 20px;
                }
            }
        }
    }
    &__table {
        @include flex(center,center,wrap);
        border-left: 1px solid $border_gray;
        border-bottom: 1px solid $border_gray;
        font-size: 1.4rem;
        margin: 50px 0 0 0;
        @include sp {
            margin: 20px 0 0 0;
        }
        &__notice {
            display: block;
            width: 50%;
            font-size: 1.4rem;
            font-weight: normal;
            padding: 0 0 0 20px;
            @include sp {
                width: 100%;
                padding: 10px 0 0 0;
            }
        }
        &__required {
            padding: 4px 10px;
            background: $yellow;
        }
        >dt {
            @include flex(between,center,wrap);
            width: 300px;
            font-weight: bold;
            background: $gray;
            padding: 20px 20px;
            border-top: 1px solid $border_gray;
            border-right: 1px solid $border_gray;
            align-self: stretch;
            @include sp {
                width: 100%;
                padding: 10px 20px;
            }
        }
        >dd {
            @include flex(start,center,wrap);
            width: calc(100% - 300px);
            padding: 10px 20px;
            border-top: 1px solid $border_gray;
            border-right: 1px solid $border_gray;
            align-self: stretch;
            >span {
                padding: 0 0 0 20px;
                width: 40%;
                @include sp {
                    width: 100%;
                    padding: 10px 0 0 0;
                }    
            }
            >span.alert__text {
                display: block;
                width: 100%;
                color: $alert_red;
                margin: 4px 0 0 0;
                padding: 0;
            }
            @include sp {
                width: 100%;
                padding: 10px 10px;
            }
        }
        .wide {
            width: 50%;
            @include sp {
                width: 100%;
            }
        }
        .narrow {
            width: 25%;
            margin: 14px 75% 0 0;
            @include sp {
                width: 50%;
                margin: 14px 50% 0 0;
            }
        }
        .post {
            width: calc(25% - 24px);
            &__icon {
                width: 24px;height: 24px;
                padding: 0;
            }
        }
        .address__wrapper {
            width:100%;
            @include flex(start,center,wrap);
            padding: 4px 0;
            >.wide {
                margin: 14px 0 0 0;
            }
        }
        input,textarea {
            border: 1px solid $border_gray;
            padding: 10px;
        }
        textarea {
            resize: none;
            width: 100%;height:260px;
            margin: 4px 0;
        }
        textarea + span {
            padding: 0;
        }
        input[type=checkbox] {
            display: none;
        }
    
    }
    &__slcb {
        width: 100%;
        .custom-select-wrapper {
            width: 100%;
            position: relative;
            display: inline-block;
            user-select: none;
        }
            .custom-select-wrapper select {
            display: none;
            }
            .custom-select {
            position: relative;
            display: inline-block;
            width: 50%;
            @include sp {
                width: 100%;
            }
            }
            .custom-select-trigger {
                position: relative;
                display: block;
                width: 100%;
                padding: 10px 60px 10px 14px;
                font-size: 1.4rem;
                font-weight: normal;
                color: $default;
                background:$white;
                border: 1px solid $border_gray;
                border-radius: 4px;
                cursor: pointer;
            }
                .custom-select-trigger:after {
                position: absolute;
                display: block;
                content: '';
                width: 10px; height: 10px;
                top: 50%; right: 25px;
                margin-top: -3px;
                border-bottom: 1px solid $default;
                border-right: 1px solid $default;
                transform: rotate(45deg) translateY(-50%);
                transition: all .4s ease-in-out;
                transform-origin: 50% 0;
                }
                .custom-select.opened .custom-select-trigger:after {
                margin-top: 3px;
                transform: rotate(-135deg) translateY(-50%);
                }
            .custom-options {
            position: absolute;
            display: block;
            top: 100%; left: 0; right: 0;
            min-width: 100%;
            margin: 15px 0;
            border: 1px solid #b5b5b5;
            border-radius: 4px;
            box-sizing: border-box;
            box-shadow: 0 2px 1px rgba(0,0,0,.07);
            background: #fff;
            transition: all .4s ease-in-out;
            
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transform: translateY(-15px);
            }
            .custom-select.opened .custom-options {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transform: translateY(0);
            z-index: 1;
            }
            .custom-options:before {
                position: absolute;
                display: block;
                content: '';
                bottom: 100%; right: 25px;
                width: 7px; height: 7px;
                margin-bottom: -4px;
                border-top: 1px solid #b5b5b5;
                border-left: 1px solid #b5b5b5;
                background: #fff;
                transform: rotate(45deg);
                transition: all .4s ease-in-out;
            }
            .option-hover:before {
                background: #f9f9f9;
            }
            .custom-option {
                position: relative;
                display: block;
                padding: 10px 10px;
                border-bottom: 1px solid #b5b5b5;
                font-size: 1.4rem;
                font-weight: 600;
                color: $default;
                cursor: pointer;
                z-index: 1;
                transition: all .4s ease-in-out;
            }
            .custom-option:first-of-type {
                border-radius: 4px 4px 0 0;
            }
            .custom-option:last-of-type {
                border-bottom: 0;
                border-radius: 0 0 4px 4px;
            }
            .custom-option:hover,
            .custom-option.selection {
                background: #f9f9f9;
            }
    }
    &__chkb {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 5px 5px 5px 40px;
        position: relative;
        width: auto;
        @include sp {
            // @include flex(center);
        }
        &:before {
            content: "";
            display: block;
            @include position(absolute,50%,n,n,5px);
            width: 26px;height:26px;
            background: $white;
            border: 1px solid $border_gray;
            border-radius: 3px;
            margin-top: -13px;
            @include sp {
                @include position(absolute,50%,n,n,0);
                margin: -13px 0 0 0;
            }
            }
        &:after {
            content: "";
            display: block;
            @include position(absolute,50%,n,n,14px);
            width: 9px;height: 14px;
            border-right: 3px solid $white;
            border-bottom: 3px solid $white;
            transform: rotate(45deg) translate3d(0,2px,0) scale3d(.7,.7,1);
            transition: transform .2s ease-in-out, opacity .2s ease-in-out;
            margin-top: -10px;
            opacity: 0;
            @include sp {
                @include position(absolute,50%,n,n,0%);
                margin: -10px 0 0 9px;
            }
        }
    }
}

.agree {
    >a {
        display: inline-block;
    }
    >.chk__item {
        margin: 10px 0 0 0;
        @include flex(center);
    }
}

.chk__item {
    width: 25%;
    @include sp {
        width: 100%;
        margin: 4px 0;
    }
}
input[type=checkbox]:checked + .contact__chkb::before {
    background: $green;
    border: 1px solid $green;
}
input[type=checkbox]:checked + .contact__chkb::after {
    opacity: 1;
    transform: rotate(45deg) scale3d(1,1,1);
}
