.merit {
    &__bg {
        &__01 {
            background: linear-gradient(to left, transparent 0%, transparent 70%, #fff 100%), url(../images/merit/img_merit_h1.jpg) center center /cover no-repeat;
        }
    }
    .mv-text-01 {
        @media only screen and (min-width: 751px) {
            font-size: 1.8rem !important;
        }
        @media only screen and (max-width: 750px) {
            font-size: 1.3rem !important;
        }
    }
    &__ttl {
        color: $default;
        font-weight: bold;
        text-align: center;
        @include sp {
            font-size: 1.7rem;
            line-height: 1.4;
        }
        @include tabpc {
            font-size: 3rem;
            line-height: 1.6;
        }
    }
    &__subttl {
        text-align: center;
        @include sp {
            font-size: 1.2rem;
            line-height: 2;
        }
        @include tabpc {
            font-size: 1.7rem;
            line-height: 2.8;
        }
    }
    &__txt {
        text-align: center;
        @include sp {
            font-size: 1.3rem;
            line-height: 1.5;
        }
        @include tabpc {
            font-size: 1.7rem;
            line-height: 1.6;
        }
    }
    .linkWrap {
        padding-top: 50px;
        margin-top: -50px;
    }
    .intro {
        @include sp {
        }
        @include tabpc {
            width: 916px;
            margin: 0 auto 80px;
            display: flex;
            justify-content: space-between;
        }
        &__box {
            font-size: 1.7rem;
            font-weight: bold;
            @include sp {
                margin-bottom: 80px;
                padding: 0 40px;
            }
        }

        &__img02 {
            @include tabpc {
                margin-top: 53px;
                width: 291px;
                height: 403px;
            }
            //&__info {
            //    padding: 10px 0;
            //    display: flex;
            //    align-items: center;
            //    background: #f5f6f8;
            //    border-radius: 10px;
            //    .left {
            //        width: 104px;
            //    }
            //    .right {
            //        width: 175px;
            //        border-left: 1px $default solid;
            //    }
            //    .infottl {
            //        font-size: 1.8rem;
            //        text-align: center;
            //        font-weight: normal;
            //    }
            //    .infotext {
            //        font-size: 2.6rem;
            //        text-align: center;
            //    }
            //}
        }
    }
    //私達の強み
    .our {
        position: relative;
        z-index: 5;
        background: $yellow;
        padding: 30px 0 50px;
        @include sp {
            padding: 10px 20px 70px;
        }
        &__inner {
            min-width: 1000px;
            max-width: 1000px;
            margin: 0 auto;
            padding: 0 20px;
            @include sp {
                min-width: inherit;
                max-width: inherit;
                padding: 0;
            }
        } 
        .sec__ttl {
            text-align: center;
        }


        &__row {
            display: flex;
            justify-content: space-between;
            margin-top: 75px;
            @include sp {
                flex-flow: column;
                margin-top: 55px;
            }
        }

        &__column {
            @include sp {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: inherit;
                &:nth-child(even) {
                    flex-flow: row-reverse;
                    .our__number,.our__caption {
                        text-align: right;
                    }
                }

                &:nth-child(n+2) {
                    margin-top: 60px;
                }
            }
        }

        &__detalis {
            width: 65%;
            @include tabpc {
                margin: 0 auto;
            }
        }

        &__number {
            font-size: 5rem;
            font-weight: 500;
            font-family: $font-famiily-jost;
            font-style: italic;
            color: $black02;
            @include tabpc {
                text-align: center;
            }
            @include sp {
                font-size: 4.5rem;
                text-align: left;
            }
        }

        &__pic {
            margin-top: 30px;
            text-align: center;
            @include tabpc {
                height: 189px;
            }
            @include sp {
                width: 35%;
                margin-top: 0;
            }
            
            img {
                max-width: 186px;
                height: auto;
                @include sp {
                    max-width: inherit;
                }
            }
        }

        &__caption {
            margin-top: 40px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: $black02;
            @include sp {
                margin-top: 5px;
                font-size: 1.7rem;
                text-align: left;
            }
        }
    }
    .merit-area {
        &__inner {
            @include tabpc {
                margin-top: 100px;
            }
        }
        &__fv {
            color: $default;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0;
            font-feature-settings: "palt";
            @include sp {
                font-size: 1.7rem;
                line-height: 1.6;
            }
            @include tabpc {
                font-size: 3rem;
                line-height: 1.5;
            }
        }
        &__nav {
            @include sp {
                margin-top: 34px;
            }
            @include tabpc {
                margin-top: 48px;
            }
        }
    }
    .meritfv {
        &__inner {
            @include sp {
                margin-top: 25px;
                padding: 0 25px;
            }
            @include tabpc {
                // min-width: 1100px;
                min-width: 1024px;
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 20px;
            }
        }
    }
    .sec__h2 {
        @include sp {
            position: relative;
            padding: 0 0 11px 0;
            font-size: 1.9rem;
        }
        @include tabpc {
            padding: 0;
            font-size: 3.4rem;
        }
    }
    .sec__h2-num {
        font-family: $font-famiily-jost;
        font-weight: 400;
        @include sp {
            position: absolute;
            top: calc(50% - 31px);
            left: 0;
            font-size: 3.4rem;
        }
        @include tabpc {
            margin-right: 40px;
            font-size: 7.2rem;
        }
    }
    .sec__h2-ttl {
        display: inline-block;
        @include sp {
            padding-left: 50px;
        }
    }
    .merit01 {
        &__intro {
            max-width: 916px;
            margin: 44px auto 58px;
            font-weight: bold;
            text-align: center;
            @include sp {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
            @include tabpc {
                font-size: 3rem;
                line-height: 4.8rem;
            }
        }
        &__block {
            max-width: 916px;
            margin: 44px auto 58px;
        }
        .block {
            max-width: 916px;
            margin: 44px auto 64px;
            &__inner {
                @include sp {
                }
                @include tabpc {
                    display: flex;
                    justify-content: center;
                }
            }
            &__ttl {
                text-align: center;
                @include sp {
                    margin-bottom: 23px;
                    font-size: 1.8rem;
                }
                @include tabpc {
                    margin-bottom: 43px;
                    font-size: 2.4rem;
                }
            }
            .detail {
                @include sp {
                }
                @include tabpc {
                    width: 445px;
                }
                &__ttl{
                    color: $green;
                    font-weight: bold;
                    @include sp {
                        margin-bottom: 20px;
                        font-size: 1.8rem;
                    }
                    @include tabpc {
                        margin-bottom: 25px;
                        font-size: 2.4rem;
                    }
                }
                &__list {
                    @include sp {
                        margin-bottom: 20px;
                    }
                    li {
                        position: relative;
                        @include sp {
                            padding-left: 16px;
                        }
                        @include tabpc {
                            padding-left: 18px;
                        }
                        span {
                            position: absolute;
                            @include sp {
                                left: -12px;
                            }
                            @include tabpc {
                                left: -14px;
                            }
                        }
                    }
                }
            }
            .img {
                text-align: center;
                @include tabpc {
                    width: 404px;
                }
                img {
                    max-width: 100%;
                    width: auto;
                }
            }
        }
        &__link {
            margin: 0 auto;
            background:$gray;
            @include sp {
                padding: 20px 20px 10px;
            }
            @include tabpc {
                padding: 40px 40px 30px;
                max-width: 1240px;
            }
            .ttl {
                margin-bottom: 40px;
                font-weight: bold;
                text-align: center;
                @include sp {
                    font-size: 2rem;
                }
                @include tabpc {
                    font-size: 2.4rem;
                }
            }
            .linklist {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                &__item {
                    margin-bottom: 11px;
                    @include sp {
                        width: 48%;
                    }
                    
                    @include tabpc {
                        width: 32%;
                    }
                    a {
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;
                        border: solid 1px $black;
                        background: $white;
                        color: $black;
                        font-size: 1.1rem;
                        font-weight: bold;
                    }
                }
            }
        }
        &__inner {
            @include sp {
                margin-top: 42px;
                padding: 0 25px;
            }
            @include tabpc {
                margin-top: 71px;
                // min-width: 1100px;
                min-width: 1024px;
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 20px;
            }
        }
        &__img {
            width: 100%;
            @include sp {
                margin: 10px auto 0;
            }
            @include tabpc {
                margin: 20px auto 0;
                max-width: 837px;
            }
        }
        &__flow {
            .flow {
                &__inner {
                    @include sp {
                        margin-top: 25px;
                    }
                    @include tabpc {
                        margin-top: 50px;
                    }
                }
                &__item {
                    position: relative;
                    margin: 0 auto;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        padding: 5px 10px;
                        width: 96%;
                        font-size: 1.5rem;
                        line-height: 1.4;
                    }
                    @include tabpc {
                        padding: 8px 0;
                        width: 458px;
                        height: 64px;
                        font-size: 2.4rem;
                        line-height: 1.75;
                    }
                    &-black {
                        color: $default;
                        border: 3px solid #666;
                        &:after {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            right: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border-style: solid;
                            border-color: $black transparent transparent transparent;
                            @include sp {
                                bottom: -27px;
                                width: 24px;
                                height: 11px;
                                border-width: 11px 12px 0 12px;
                            }
                            @include tabpc {
                                bottom: -37px;
                                width: 32px;
                                height: 14px;
                                border-width: 14px 16px 0 16px;
                            }
                        }
                    }
                    &-green {
                        color: $green;
                        border: 3px solid $green;
                        @include sp {
                            margin-top: 25px;
                        }
                        @include tabpc {
                            margin-top: 40px;
                        }
                    }
                }
                .merit__txt {
                    @include sp {
                        margin-top: 16px;
                        text-align: left;
                    }
                    @include tabpc {
                        margin-top: 23px;
                    }
                }
            }
        }
        &__enduser {
            border-bottom: 1px solid $dark_gray;
            @include sp {
                margin: 24px 0 0;
                padding-bottom: 49px;
            }
            @include tabpc {
                margin: 45px 0 0;
                padding-bottom: 97px;
            }
            .merit__ttl{
                @include sp {
                    font-size: 1.5rem;
                    line-height: 1.4;
                }
                @include tabpc {
                    font-size: 2.4rem;
                    line-height: 1.75;
                }
             }
            .merit__txt {
                @include sp {
                    margin-top: 10px;
                }
                @include tabpc {
                    margin-top: 23px;
                }
            }
        }
        &__radarchart {
            //border-bottom: 1px solid $dark_gray;
            @include sp {
                margin: 36px 0 0;
                padding-bottom: 50px;
            }
            @include tabpc {
                margin: 72px 0 0;
                padding-bottom: 100px;
            }
            .merit__txt {
                @include sp {
                    margin-top: 19px;
                    text-align: left;
                }
                @include tabpc {
                    margin-top: 53px;
                }
            }
            .radarchart {
                &__inner {
                    @include sp {
                        margin-top: 31px;
                    }
                    @include tabpc {
                        margin-top: 72px;
                    }
                }
                &__wrap {
                    @include tabpc {
                        display: flex;
                        justify-content: center;
                    }
                }
                &__item {
                    &:first-of-type {
                        @include tabpc {
                            margin-right: 97px;
                        }
                    }
                    &:last-of-type{
                        @include sp {
                            margin-top: 33px;
                        }
                    }
                    &-green {
                        .radarchart__ttl {
                            color: $green;
                        }
                    }
                }
                &__ttl {
                    color: $default;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        font-size: 1.5rem;
                        line-height: 1.4;                        
                    }
                    @include tabpc {
                        font-size: 2rem;
                        line-height: 1.4;
                    }
                }
                &__img {
                    @include sp {
                        margin: 12px auto 0;
                        width: 171px;
                    }
                    @include tabpc {
                        margin: 24px auto 0;
                        width: 228px;
                    }
                }
                &__txt {
                    color: $default;
                    text-align: center;
                    @include sp {
                        margin-top: 17px;
                        font-size: 1.3rem;
                        line-height: 1.5;
                    }
                    @include tabpc {
                        margin-top: 21px;
                        font-size: 1.7rem;
                        line-height: 1.6;
                    }
                }
                &__list {
                    width: 100%;
                    @include sp {
                        margin: 35px auto 0;
                        max-width: 270px;
                    }
                    @include tabpc {
                        margin: 45px auto 0;
                        max-width: 350px;
                    }
                    .border-list {
                        &__item {
                            position: relative;
                            color: $default;
                            @include sp {
                                padding-left: 50px;
                                font-size: 1.1rem;
                                line-height: 1.6;
                            }
                            @include tabpc {
                                padding-left: 60px;
                                font-size: 1.4rem;
                                line-height: 1.7;
                            }
                            &:before {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                top: calc(50% - 3px);
                                left: 0;
                                height: 4px;
                                @include sp {
                                    width: 40px;
                                }
                                @include tabpc {
                                    width: 50px;
                                }
                            }
                            &-enduser {
                                &:before {
                                    background-color: $yellow;
                                }
                            }
                            &-past {
                                &:before {
                                    background-color: #666;
                                }
                            }
                            &-now {
                                &:before {
                                    background-color: $green;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__capture {
            @include sp {
                margin: 36px 0 0;
                padding-bottom: 25px;
            }
            @include tabpc {
                margin: 69px 0 0;
                padding-bottom: 100px;
            }
            .capture {
                &__wrap {
                    @include sp {
                        margin-top: 25px;
                    }
                    @include tabpc {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 80px;
                    }
                }
                &__item {
                    @include tabpc {
                        width: calc((100% / 2) - 20px);
                    }
                    &:not(:first-of-type) {
                        @include sp {
                            margin-top: 33px;
                        }
                    }
                }
                &__label {
                    @include sp {
                        background: url('../images/merit/bg_merit_label_yellow_sp.png') left/92px no-repeat;
                    }
                    @include tabpc {
                        background: url('../images/merit/bg_merit_label_yellow_pc.png') left/auto no-repeat;
                    }
                    span {
                        color: $default;
                        font-weight: bold;
                        @include sp {
                            padding: 4px 8px;
                            font-size: 1.2rem;
                            line-height: 2;
                        }
                        @include tabpc {
                            padding: 4px 12px;
                            font-size: 1.4rem;
                            line-height: 2;
                        }
                    }
                }
                &__img {
                    @include sp {
                        margin: 14px auto 0;
                        width: 203px;
                    }
                    @include tabpc {
                        margin: 13px auto 0;
                        width: 305px;
                    }
                }
                &__ttl {
                    color: $default;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        margin: 27px 0 0;
                        font-size: 1.5rem;
                        line-height: 1.4;
                    }
                    @include tabpc {
                        margin: 40px 0 0;
                        font-size: 2.4rem;
                        line-height: 1.3;
                    }
                }
                &__txt {
                    color: $default;
                    @include sp {
                        margin: 24px 0 0;
                        font-size: 1.3rem;
                        line-height: 1.5;
                    }
                    @include tabpc {
                        margin: 31px 0 0;
                        font-size: 1.7rem;
                        line-height: 1.6;
                    }
                }
                &__check {
                    margin-top: 56px;
                    .check {
                        &__inner {
                            @include tabpc {
                                position: relative;
                                max-width: 1000px;
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                        &__img {
                            width: 100%;
                            margin: 0 auto;
                            @include tabpc {
                                max-width: 943px;
                            }
                        }
                        &__list {
                            width: 100%;
                            @include tabpc {
                                position: absolute;
                                top: 320px;
                                right: 0;
                                max-width: 640px;
                            }
                        }
                    }
                    .balloon {
                        &__inner {
                            position: relative;
                            border: 3px solid $yellow;
                            @include sp {
                                border-radius: 5px;
                                margin-top: 25px;
                            }
                            @include tabpc {
                                border-radius: 8px;
                            }
                            &:before {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                border-style: solid;
                                @include sp {
                                    top: -23px;
                                    left: 61px;
                                    border-color: transparent transparent $yellow transparent;
                                    border-width: 0 8px 20px 8px;
                                }
                                @include tabpc {
                                    top: 100px;
                                    left: -72px;
                                    border-color: transparent $yellow transparent transparent;
                                    border-width: 15px 70px 15px 0;
                                }
                            }
                            &:after {
                                @include tabpc {
                                    content: '';
                                    display: inline-block;
                                    position: absolute;
                                    top: 101px;
                                    left: -62px;
                                    border-color: transparent $white transparent transparent;
                                    border-style: solid;
                                    border-width: 14px 70px 14px 0;
                                }
                            }
                        }
                        &__ttl {
                            background-color: $yellow;
                            color: $default;
                            font-weight: bold;
                            text-align: center;
                            @include sp {
                                padding: 7px 0;
                                font-size: 1.3rem;
                                line-height: 1;
                            }
                            @include tabpc {
                                padding: 14px 0 12px;
                                font-size: 2rem;
                                line-height: 1.4;
                            }
                        }
                        &__detail {
                            background-color: $white;
                            @include sp {
                                padding: 11px 17px;
                                border-radius: 0 0 5px 5px;
                            }
                            @include tabpc {
                                padding: 25px 30px;
                                border-radius: 0 0 8px 8px;
                            }
                        }
                        &__area {
                            &:not(:first-of-type) {
                                @include sp {
                                    margin-top: 15px;
                                }
                                @include tabpc {
                                    margin-top: 30px;
                                }
                            }
                        }
                        &__subttl {
                            font-weight: bold;
                            @include sp {
                                font-size: 1.2rem;
                                line-height: 1.5;
                            }
                            @include tabpc {
                                font-size: 1.7rem;
                                line-height: 1.6;
                            }
                        }
                        &__subtxt {
                            @include sp {
                                font-size: 1.1rem;
                                line-height: 1.4;
                            }
                            @include tabpc {
                                font-size: 1.5rem;
                                line-height: 1.6;
                            }
                            p {
                                @include sp {
                                    text-indent: -11px;
                                    padding-left: 11px;
                                }
                                @include tabpc {
                                    text-indent: -15px;
                                    padding-left: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__accodion {
            @include tabpc {
                margin-top: 170px;
            }
        }
    }
    .merit02 {
        &__inner {
            background-color: $gray;
            @include sp {
                margin-top: 50px;
                padding-top: 40px;
                padding-bottom: 50px;
            }
            @include tabpc {
                margin-top: 121px;
                padding-top: 80px;
                padding-bottom: 100px;
            }
        }
        &__wrap {
            @include sp {
                padding: 0 25px;
            }
            @include tabpc {
                // min-width: 1100px;
                min-width: 1024px;
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 20px;
            }
        }
        .merit__txt {
            @include sp {
                margin-top: 25px;
                text-align: left;
            }
            @include tabpc {
                margin-top: 50px;
            }
        }
        &__menu {
            @include sp {
                margin-top: 22px;
            }
            @include tabpc {
                margin-top: 45px;
            }
        }
        .menu02 {
            @include sp {
                background: url('../images/merit/img_merit_contents02_01_sp.png') top no-repeat;
                background-size: 95% 98%;
            }
            @media screen and (min-width: 645px) and (max-width: 768px) {
                background: url('../images/merit/img_merit_contents02_01_pc.png') center/contain no-repeat;
            }
            &__inner {
                display: flex;
                justify-content: space-between;
                @include sp {
                    margin-top: 10px;
                }
                @include tabpc {
                    background: url('../images/merit/img_merit_contents02_01_pc.png') center no-repeat;
                }
            }
            &__spblock {
                @include sp {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            &__item {
                background-color: rgba(255,255,255,0.8);
                border: 3px solid $green;
                @include sp {
                    padding: 12px 8px;
                    width: 100%;
                }
                @include tabpc {
                    padding: 22px 20px;
                    width: 370px;
                }
                &:not(:first-of-type) {
                    @include sp {
                        margin-top: 5px;
                    }
                    @include tabpc {
                        margin-top: 20px;
                    }
                }
            }
            &__block {
                @include sp {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc((100% - 5px) / 2);
                }
                &-center {
                    @include tabpc {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            &__ttl {
                color: $green;
                font-weight: bold;
                @include sp {
                    font-size: 1.4rem;
                    line-height: 1.1;
                }
                @include tabpc {
                    font-size: 2.4rem;
                    line-height: 1.3;
                }
            }
            &__subttl {
                color: $default;
                font-weight: bold;
                letter-spacing: 0;
                font-feature-settings: "palt";
                @include sp {
                    margin-top: 6px;
                    font-size: 1.1rem;
                    line-height: 1.4;
                }
                @include tabpc {
                    margin-top: 10px;
                    font-size: 1.7rem;
                    line-height: 1.9;
                }
            }
            &__txt {
                color: $default;
                @include sp {
                    margin-top: 11px;
                    font-size: 1rem;
                    line-height: 1.4;
                    letter-spacing: 0;
                    font-feature-settings: "palt";
                }
                @include tabpc {
                    margin-top: 10px;
                    font-size: 1.5rem;
                    line-height: 1.6;
                }
            }
            &__img {
                @include sp {
                    margin-top: 9px;
                }
                @include tabpc {
                    margin-top: 15px;
                }
                li {
                    &:not(:first-of-type) {
                        @include sp {
                           margin-top: 6px;
                        }
                        @include tabpc {
                            margin-top: 12px;
                        }
                    }
                }
            }
            &__icn {
                &01 {
                    @include sp {
                        max-width: 65px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 131px;
                        width: 100%;
                    }
                }
                &02 {
                    @include sp {
                        max-width: 132px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 265px;
                        width: 100%;
                    }
                }
                &03 {
                    @include sp {
                        max-width: 102px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 204px;
                        width: 100%;
                    }
                }
                &04 {
                    @include sp {
                        max-width: 67px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 135px;
                        width: 100%;
                    }
                }
                &05 {
                    @include sp {
                        max-width: 102px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 205px;
                        width: 100%;
                    }
                }
                &06 {
                    @include sp {
                        max-width: 100px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 200px;
                        width: 100%;
                    }
                }
                &07 {
                    @include sp {
                        max-width: 122px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 245px;
                        width: 100%;
                    }
                }
                &08 {
                    @include sp {
                        max-width: 114px;
                        width: 100%;
                    }
                    @include tabpc {
                        max-width: 228px;
                        width: 100%;
                    }
                }
            }
            .menu02__center {
                .menu02__img01 {
                    @include sp {
                        margin: 25px auto 0;
                        width: 132px;
                    }
                    @include tabpc {
                        width: 264px;
                    }
                }
                .menu02__ttl {
                    text-align: center;
                    @include sp {
                        margin-top: 11px;
                    }
                    @include tabpc {
                        margin-top: 33px;
                    }
                }
                .menu02__box {
                    color: $default;
                    @include sp {
                        margin-top: 11px;
                        font-size: 1rem;
                        line-height: 1.4;
                    }
                    @include tabpc {
                        margin-top: 22px;
                        font-size: 1.7rem;
                        line-height: 1.6;
                    }
                    p {
                        @include sp {
                            padding-left: 14px;
                            text-indent: -14px;
                        }
                        @include tabpc {
                            padding-left: 17px;
                            text-indent: -17px;
                        }
                    }
                }
            }
        }
        &__arrow {
            border-bottom: 1px solid $dark_gray;
            @include sp {
                margin-top: 10px;
                padding-bottom: 50px;
            }
            @include tabpc {
                margin-top: 20px;
                padding-bottom: 175px;
            }
            .label02 {
                &__inner {
                    display: flex;
                    justify-content: space-between;
                }
                &__wrap {
                    @include sp {
                        width: calc(( 100% - 5px) / 2);
                    }
                    @include tabpc {
                        width: calc(( 100% - 40px) / 2);
                        height: 48px;
                    }
                }
                &__item {
                    position: relative;
                    background-color: $yellow;
                    @include sp {
                        border-radius: 12px;
                    }
                    @include tabpc {
                        border-radius: 24px;
                    }
                    &:before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        right: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        background-color: $yellow;
                        @include sp {
                            bottom: -14px;
                            width: 8px;
                            height: 10px;
                        }
                        @include tabpc {
                            bottom: -30px;
                            width: 16px;
                            height: 20px;
                        }
                    }
                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        right: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        border-style: solid;
                        border-color: $yellow transparent transparent transparent;
                        @include sp {
                            bottom: -24px;
                            border-width: 10px 9px 0 9px;
                            width: 18px;
                            height: 10px;
                        }
                        @include tabpc {
                            bottom: -48px;
                            border-width: 19px 18px 0 17px;
                            width: 35px;
                            height: 19px;
                        }
                    }
                }
                &__ttl {
                    color: $default;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        padding: 5px 0;
                        font-size: 1.2rem;
                        line-height: 1.3;
                    }
                    @include tabpc {
                        padding: 8px 0;
                        font-size: 2rem;
                        line-height: 1.6;
                    }
                }
                &__name {
                    color: $green;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        margin-top: 21px;
                        font-size: 1.6rem;
                        line-height: 1;
                    }
                    @include tabpc {
                        margin-top: 45px;
                        font-size: 3rem;
                        line-height: 1;
                    }
                }
            }
        }
        &__chain {
            border-bottom: 1px solid $dark_gray;
            @include sp {
                margin-top: 36px;
                padding-bottom: 81px;
            }
            @include tabpc {
                margin-top: 72px;
                padding-bottom: 97px;
            }
            .chain02 {
                &__img {
                    width: 100%;
                    @include sp {
                        position: relative;
                        margin: 26px auto 0;
                    }
                    @include tabpc {
                        max-width: 1180px;
                        margin: 49px auto 0;
                    }
                    .icon-glass {
                        @include sp {
                            position: absolute;
                            width: 26px;
                            right: 0;
                            bottom: -40px;
                        }
                    }
                    img {
                        vertical-align: bottom;
                    }
                }
            }
        }
        &__point {
            @include sp {
                margin-top: 36px;
            }
            @include tabpc {
                margin-top: 71px;
            }
            .point02 {
                &__wrap {
                    @include sp {
                        margin-top: 22px;
                    }
                    @include tabpc {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 49px;
                    }
                }
                &__item {
                    background-color: $white;
                    border: 3px solid $yellow;
                    @include sp {
                        width: 100%;
                    }
                    @include tabpc {
                        width: calc( (100% - 40px ) / 3 );
                    }
                    &:not(:first-of-type) {
                        @include sp {
                            margin-top: 10px;
                        }
                    }
                }
                &__ttl {
                    background-color: $yellow;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        padding: 6px 0;
                        font-size: 1.2rem;
                        line-height: 1.2;
                    }
                    @include tabpc {
                        padding: 10px 0;
                        height: 49px;
                        font-size: 2rem;
                        line-height: 1.4;
                    }
                }
                &__txt {
                    font-weight: bold;
                    @include sp {
                        padding: 12px 18px;
                        font-size: 1.5rem;
                        line-height: 1.4;
                    }
                    @include tabpc {
                        padding: 22px 24px;
                        font-size: 2.4rem;
                        line-height: 1.3;
                        letter-spacing: -1px;
                    }
                }
            }
        }
    }
    .merit03 {
        &__inner {
            @include sp {
                margin-top: 37px;
                padding: 0 25px 65px;
            }
            @include tabpc {
                margin-top: 80px;
                // min-width: 1100px;
                min-width: 1024px;
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 20px 151px;
            }
        }
        &__top {
            .merit03__subttl{
                width: 100%;
                font-size: 3rem;
                font-weight: normal;
                border-bottom: 1px solid #c6c9d3;
                padding-bottom: 30px;
                margin-bottom: 30px;
                text-align: center;
                @include sp{
                    font-size: 1.7rem;
                }
            }
            @include sp {
                margin-top: 37px;
            }
            @include tabpc {
                margin-top: 50px;
            }
            .top03 {
                &__inner {
                    display: flex;
                    border-bottom: 1px solid $dark_gray;
                    @include sp {
                        flex-wrap: wrap;
                        padding-bottom: 50px;
                    }
                    @include tabpc {
                        justify-content: space-between;
                        padding-bottom: 97px;
                    }
                }
                &__wrap {
                    @include sp {
                        width: 100%;
                        order: 2;
                    }
                    @include tabpc {
                        width: 620px;
                    }
                }
                &__ttl {
                    color: $default;
                    font-weight: bold;
                    @include sp {
                        margin-top: 27px;
                        font-size: 1.5rem;
                        line-height: 1.4;
                        text-align: center;
                    }                    
                    @include tabpc {
                        font-size: 2.4rem;
                        line-height: 1.3;
                    }
                }
                &__txt {
                    color: $default;
                    @include sp {
                        margin-top: 21px;
                        font-size: 1.3rem;
                        line-height: 1.5;
                    }                    
                    @include tabpc {
                        margin-top: 30px;
                        font-size: 1.7rem;
                        line-height: 1.6;
                        letter-spacing: 0;
                    }
                }
                &__img {
                    width: 100%;
                    margin: 0 auto;
                    @include sp {
                        order: 1;
                        max-width: 172px;
                    }
                    @include tabpc {
                        max-width: 278px;
                    }
                }
            }
        }
        &__diversity {
            border-bottom: 1px solid $dark_gray;
            @include sp {
                margin-top: 36px;
                padding-bottom: 50px;
            }
            @include tabpc {
                margin-top: 69px;
                padding-bottom: 100px;
            }
            .merit__ttl {
                @include sp {
                    margin-top: 13px;
                }
            }
            .merit__txt {
                @include sp {
                    margin-top: 23px;
                    text-align: left;
                }
                @include tabpc {
                    margin-top: 42px;
                }
            }
            .diversity {
                &__wrap {
                    display: flex;
                    justify-content: center;
                    @include sp {
                        margin-top: 21px;
                    }
                    @include tabpc {
                        margin-top: 30px;
                    }
                }
                &__item {
                    position: relative;
                    border: 3px solid $green;
                    border-radius: 50%;
                    @include sp {
                        min-width: 169px;
                        min-height: 169px;
                    }
                    @media screen and (min-width: 320px) and (max-width: 374px){
                        min-width: 138px;
                        min-height: 138px;
                    }
                    @include tabpc {
                        width: 338px;
                        height: 338px;
                    }
                    &:first-of-type {
                        @include sp {
                            right: -15px;
                        }
                        @media screen and (min-width: 320px) and (max-width: 374px){
                            right: -10px;
                        }
                        @include tabpc {
                            right: -10px;
                        }
                    }
                    &:last-of-type {
                        @include sp {
                            left: -15px;
                        }
                        @media screen and (min-width: 320px) and (max-width: 374px){
                            left: -10px;
                        }
                        @include tabpc {
                            left: -10px;
                        }
                    }
                }
                &__ttl {
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    color: $green;
                    font-weight: bold;
                    text-align: center;
                    @include sp {
                        font-size: 1.5rem;
                        line-height: 1.4;
                    }
                    @include tabpc {
                        font-size: 2.4rem;
                        line-height: 1.75;
                    }
                }
            }
        }
        &__evaluation01 {
            border-bottom: 1px solid $dark_gray;
            @include sp {
                margin-top: 36px;
                padding-bottom: 50px;
            }
            @include tabpc {
                margin-top: 69px;
                padding-bottom: 100px;
            }
            .merit__ttl {
                @include sp {
                    margin-top: 13px;
                }
            }
            .merit__txt {
                @include sp {
                    margin-top: 23px;
                    text-align: left;
                }
                @include tabpc {
                    margin-top: 47px;
                }
            }
            .evaluation01 {
                &__img {
                    max-width: 554px;
                    width: 100%;
                    margin: 35px auto 0;
                }
            }
        }
        &__evaluation02 {
            border-bottom: 1px solid $dark_gray;
            @include sp {
                margin-top: 35px;
                padding-bottom: 50px;
            }
            @include tabpc {
                margin-top: 72px;
                padding-bottom: 100px;
            }
            .merit__txt {
                margin-top: 47px;
            }
            .evaluation02 {
                &__scroll {
                    @include sp {
                        display: block;
                        overflow-x: scroll;
                        white-space: nowrap;
                        -webkit-overflow-scrolling: touch;
                    }
                }
                &__wrap {
                    @include sp {
                        display: block;
                        margin-top: 27px;
                        width: 100%;
                        overflow-x: auto;
                        overflow-scrolling: touch;
                        white-space: nowrap;
                    }
                    @include tabpc {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 49px;
                    }
                }
                &__item {
                    border: 3px solid $yellow;
                    @include sp {
                        display: inline-block;
                        width: 265px;
                    }
                    @include tabpc {
                        width: calc((100% - 40px) / 3);
                    }
                }
                &__label {
                    display: inline-block;
                    background: url('../images/merit/bg_merit_label_yellow02.png') center no-repeat;
                    @include sp {
                        padding: 1px 8px;
                        background-size: cover;
                        width: 79px;
                        height: 27px;
                    }
                    @include tabpc {
                        padding: 0px 10px;
                        width: 93px;
                        height: 32px;
                    }
                    > span {
                        font-weight: bold;
                        @include sp {
                            font-size: 1.2rem;
                            line-height: 1.9;
                        }
                        @include tabpc {
                            font-size: 1.4rem;
                            line-height: 2;
                        }
                    }
                }
                &__ttl {
                    font-weight: bold;
                    letter-spacing: 0;
                    font-feature-settings: "palt";
                    @include sp {
                        margin-top: 16px;
                        padding: 0 18px;
                        height: 63px;
                        font-size: 1.5rem;
                        line-height: 1.4;
                        white-space: normal;
                    }
                    @include tabpc {
                        margin-top: 35px;
                        padding: 0 27px;
                        font-size: 2.4rem;
                        line-height: 1.3;
                    }
                }
                &__txt {
                    letter-spacing: 0;
                    @include sp {
                        margin-top: 15px;
                        padding: 0 18px 15px;
                        font-size: 1.3rem;
                        line-height: 1.5;
                        white-space: normal;
                    }
                    @include tabpc {
                        margin-top: 30px;
                        padding: 0 27px 20px;
                        font-size: 1.7rem;
                        line-height: 1.6;
                    }
                    ul {
                        li {
                            @include sp {
                                text-indent: -13px;
                                padding-left: 13px;
                            }
                            @include tabpc {
                                text-indent: -17px;
                                padding-left: 17px;
                            }
                        }
                    }
                }
            }
        }
        .environment01 {
            &__inner {
                width: 100%;
                @include sp {
                    margin: 36px auto 0;
                }
                @include tabpc {
                    max-width: 1180px;
                    margin: 69px auto 0;
                }
            }
            &__wrap {
                @include sp {
                    margin-top: 23px;
                }
                @include tabpc {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 47px;
                }
            }
            &__item {
                @include sp {
                    width: 100%;
                }
                @include tabpc {
                    width: calc((100% - 100px) / 2);
                }
                &:not(:first-of-type) {
                    @include sp {
                        margin-top: 34px;
                    }
                }
            }
            &__img {
                width: 100%;
                margin: 0 auto;
                &01 {
                    @include sp {
                        max-width: 145px;
                    }
                    @include tabpc {
                        max-width: 222px;
                    }
                }
                &02 {
                    @include sp {
                        max-width: 205px;
                    }
                    @include tabpc {
                        max-width: 327px;
                    }
                }
            }
            &__ttl {
                font-weight: bold;
                text-align: center;
                @include sp {
                    margin-top: 25px;
                    font-size: 1.5rem;
                    line-height: 1.4;
                }
                @include tabpc {
                    margin-top: 46px;
                    font-size: 2.4rem;
                    line-height: 1.3;
                }
            }
            &__txt {
                @include sp {
                    margin-top: 20px;
                    font-size: 1.3rem;
                    line-height: 1.5;
                }
                @include tabpc {
                    margin-top: 32px;
                    font-size: 1.7rem;
                    line-height: 1.6;
                }
            }
        }
        &__qualification {
            @include sp {
                margin-top: 45px;
            }
            @include tabpc {
                margin-top: 84px;
            }
            .qualification01 {
                &__ttl {
                    font-weight: bold;
                    @include sp {
                        font-size: 1.5rem;
                        line-height: 1;
                    }
                    @include tabpc {
                        font-size: 2.4rem;
                        line-height: 1.3;
                    }
                }
                &__scroll {
                    @include sp {
                        display: block;
                        margin-top: 9px;
                        width: 100%;
                        overflow-x: auto;
                        overflow-scrolling: touch;
                        white-space: nowrap;
                    }
                }
                .tbl01 {
                    border-collapse: collapse;
                    @include sp {
                        width: 718px;
                    }
                    @include tabpc {
                        margin-top: 21px;
                    }
                    &__w {
                        &01 {
                            @include sp{
                                width: 147px;
                            }
                            @include tabpc {
                                width: 306px;
                            }
                        }
                        &02,
                        &03 {
                            @include sp{
                                width: 157px;
                            }
                            @include tabpc {
                                width: 412px;
                            }
                        }
                        &04 {
                            @include sp{
                                width: 80px;
                            }
                            @include tabpc {
                                width: 120px;
                            }
                        }
                    }
                    &__head {
                        th {
                            background-color: $yellow;
                            border: 1px solid $dark_gray;
                            font-weight: bold;
                            text-align: left;
                            @include sp {
                                padding: 9px 11px;
                                font-size: 1.3rem;
                                line-height: 1.2;
                                white-space: normal;
                            }
                            @include tabpc {
                                padding: 0 21px;
                                height: 51px;
                                font-size: 1.7rem;
                                line-height: 1.9;
                            }
                        }
                    }
                    &__body {
                        td {
                            border: 1px solid $dark_gray;
                            @include sp {
                                padding: 11px 10px;
                                font-size: 1.3rem;
                                line-height: 1.5;
                                white-space: normal;
                            }
                            @include tabpc {
                                padding: 34px 18px;
                                font-size: 1.7rem;
                                line-height: 1.6;
                            }
                        }
                    }
                    &__num {
                        text-align: center!important;
                    }
                }
            }
        }
        &__accodion {
            @include sp {
                margin-top: 25px;
            }
            @include tabpc {
                margin-top: 50px;
            }
        }
    }
    .accodion {
        @keyframes accodionShow {
            from {
                opacity: 0;
                transform: translateY(5px);
            }
            to {
                opacity: 1;
                transform: translateY(0px);
            }
        }
        &__inner {
            background-color: $gray;
        }
        &__wrap {
            @include sp {
                padding: 20px 15px;
            }
            @include tabpc {
                display: flex;
                justify-content: space-between;
                padding: 29px 38px;
            }
        }
        &__item {
            @include tabpc {
                width: calc(100% / 2);
            }
            &:first-of-type {
                @include sp {
                    margin-bottom: 15px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $white;
                }
                @include tabpc {
                    padding-right: 40px;
                    border-right: 2px solid $white;
                }
            }
            &:last-of-type {
                @include tabpc {
                    padding-left: 40px;
                }
            }
        }
        &__ttl {
            position: relative;
            font-weight: bold;
            @include sp {
                padding-right: 50px;
                font-size: 1.3rem;
                line-height: 1.5;
                cursor: pointer;
            }
            @include tabpc {
                padding-left: 13px;
                font-size: 1.7rem;
                line-height: 1.6;
            }
            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                @include sp {
                    top: calc(50% - 6px);
                    right: 0;
                    border-width: 9px 7px 0 7px;
                    border-style: solid;
                    border-color: $black transparent transparent transparent;
                }
                @include tabpc {
                    top: calc(50% - 6px);
                    left: 0;
                    border-width: 6px 0 6px 8px;    
                    border-style: solid;
                    border-color: transparent transparent transparent $black;
                }
            }
        }
        &__txt {
            @include sp {
                display: none;
                margin-top: 9px;
                font-size: 1.1rem;
                line-height: 1.4;
            }
            @include tabpc {
                margin-top: 15px;
                font-size: 1.5rem;
                line-height: 1.6;
            }
        }
        .is-open {
            display: block;
            animation: accodionShow 0.3s linear 0s;
        }
        .is-close {
            display: none;
        }
        .accodion__ttl.is-active {
            &:before {
                @include sp {
                    top: calc(50% - 6px);
                    right: 0;
                    border-width: 0 7px 9px 7px;
                    border-style: solid;
                    border-color: transparent transparent $black transparent;
                }
            }
        }
    }
}