@for $value from 0 through 40 {
  .u-m#{$value} {
    margin: $value * 5px !important;
  }
}

@for $value from 0 through 40 {
  .u-mt#{$value} {
    margin-top: $value * 5px !important;
  }
}

@for $value from 0 through 40 {
  .u-mb#{$value} {
    margin-bottom: $value * 5px !important;
  }
}

@for $value from 0 through 40 {
  .u-mr#{$value} {
    margin-right: $value * 5px !important;
  }
}

@for $value from 0 through 40 {
  .u-ml#{$value} {
    margin-left: $value * 5px !important;
  }
}
