.access {
    &__inner {
        @include sp {
            margin: 0 auto 65px;
        }
        @include tabpc {
            margin: 0 auto 150px;
        }
    }
    .box {
        &__inner {
            margin: 0 0 20px;
            border: 1px solid #cccccc;
            background: #f3f3f3;
            @include sp {
                padding: 20px;
            }
            @include tabpc {
                display: flex;
                padding: 30px;
            }
        }
        &__pic {
            width: 100%;
            @include sp {
                margin: 0 0 10px 0;
            }
            @include tabpc {
                margin: 0 30px 0 0;
                max-width: 312px;
            }
            img {
                width: 100%;
                vertical-align: bottom;
            }
        }
        &__ttl {
            @include sp {
                margin: 0 0 15px;
                font-size: 1.6rem;
            }
            @include tabpc {
                margin: 0 0 30px;
                font-size: 2.0rem;
            }
        }
        &__text {
            a[href^="tel:"] {
                display: inline-block;
            }
        }
        &__tag {
            @include sp {
                margin: 0 0 15px;
            }
            @include tabpc {
                margin: 0 0 30px;
            }
        }
    }
    .box02 {
        &__inner {
            @include sp {
                margin: 0 0 50px;
            }
            @include tabpc {
                display: flex;
                margin: 0 0 80px;
            }
        }
        &__pic {
            width: 100%;
            @include sp {
                margin: 0 0 10px 0;
            }
            @include tabpc {
                margin: 0 30px 0 0;
                max-width: 312px;
            }
            img {
                width: 100%;
                vertical-align: bottom;
            }
        }
        &__text {
            @include tabpc {
                line-height: 1.8;
            }
            a[href^="tel:"] {
                display: inline-block;
            }
        }
        &__link {
            @include tabpc {
                margin: 20px 0 0;
            }
            @include sp {
                margin: 10px 0 0;
            }
        }
    }
    .box03 {
        &__inner {
            @include sp {
                margin: 0 0 50px;
            }
            @include tabpc {
                margin: 0 0 80px;
            }
        }
        &__detail {
            @include tabpc {
                line-height: 1.8;
            }
        }
        &__big {
            @include sp {
                margin: 0 0 15px;
                font-size: 1.6rem;
            }
            @include tabpc {
                margin: 0 0 30px;
                font-size: 2rem;
            }
        }
        &__contact {
            text-align: center;
            @include sp {
                margin: 25px 0 0;
            }
            @include tabpc {
                margin: 50px 0 0;
            }
            .u-button03 {
                @include sp {
                    width: 80%;
                }
                &:hover {
                    background-color: $black;
                    border: solid 1px $black;
                    color: $white;
                }
            }
        }
    }
    .sec-level3__ttl {
        @include sp {
            margin-bottom: 50px;
        }
        @include tabpc {
            margin-bottom: 100px;
        }
    }
    .u-heading02 {
        @include sp {
            margin-bottom: 25px;
        }
        @include tabpc {
            margin-bottom: 50px;
        }
        &:not(:first-of-type) {
            @include sp {
                margin-top: 50px;
            }
            @include tabpc {
                margin-top: 100px;
            }
        }
        &__inner {
            > span {
                @include sp {
                    line-height: 3rem;
                }
            }
        }
    }
}