.case {
    &__bg {
        &__01 {
            background: linear-gradient(to left, transparent 0%, transparent 70%, #fff 100%), url(../images/case/img_case_h1.jpg) center center /cover no-repeat;
        }
    }
    .case-area {
        background-color: $white;
        &__inner {
            @include sp {
                margin: 16px auto 0;
                padding: 0 25px;
            }
            @include tabpc {
                display: flex;
                flex-wrap: wrap;
                // min-width: 1100px;
                min-width: 1024px;
                max-width: 1280px;
                margin: 100px auto 0;
                padding: 0 20px;
            }
        }
        &__tabs {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0 0 30px;
        }

        &__tab {
            width: 244px;
            &.is-active {
                a {
                    background-color: $green;
                    color: white;
                    &:hover {
                        background-color: $green;
                    }
                }
            }
            a {
                display: block;
                background-color: $gray;
                padding: 11px 10px;
                font-size: 1.5rem;
                color: $default;
                text-align: center;
                &:hover {
                    opacity: .7;
                }
            }
        }
        &__select {
            @include sp {
                margin: 0 0 25px
            }
        }
        .tab__column {
            display: none;
            &.tab__show {
                display: block;
                animation-name: fade-in;
                animation-duration: .7s; //遷移時間
            }

            @keyframes fade-in {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
        }
    }
    .case01 {
        position: relative;
        z-index: 3;
        
        &__inner {
            @include sp {
                margin: 0 0 65px;
            }
            @include tabpc {
                margin: 0 0 150px;
            }
        }
        &__count {
            padding: 0 20px;
            @include sp {
                margin: 0 0 25px;
            }
            @include tabpc {
                margin: 0 0 30px;
            }
        }
        &__num {
            @include sp {
                font-size: 1.3rem;
            }
            @include tabpc {
                font-size: 1.5rem;
            }
        }
        &__row {
            @include tabpc {
                display: flex;
                flex-wrap: nowrap;
                margin: 40px auto 0;
                
            }
        }

        &__column {
            box-shadow: 11px 11px 7px rgba(0,0,0,.05);
            @include sp {
                width: 100%;
            }
            @include tabpc {
                width: calc((100% / 4) - 21px); //width: 289px;
            }
            &:not(:first-of-type) {
                @include sp {
                    margin-top: 25px;
                }
            }
            &:not(:nth-of-type(4n)) {
                @include tabpc {
                    margin-right: 28px;
                }
            }
            &:nth-of-type(n+5) {
                @include tabpc {
                    margin-top: 60px;
                }
            }
        }

        &__link {
            display: block;
            position: relative;
            top: 0;
            &:hover {
                top: -3px;
                color: inherit;
            }
        }

        &__pic {
            text-align: center;
            img {
                vertical-align: middle;
            }
        }

        &__content {
            @include sp {
                padding: 10px 15px 25px;
            }
            @include tabpc {
                padding: 15px 20px 50px;
            }
        }

        &__stats {
            display: flex;
            flex-wrap: wrap;
        }

        &__tag {
            display: inline-block;
            border: 1px solid $green;
            color: $green;
            text-align: center;
            @include sp {
                margin: 5px 5px 0 0;
                padding: 0px 8px;
                font-size: 1.1rem;
                border-radius: 3px;
            }
            @include tabpc {
                margin: 5px 5px 0 0;
                padding: 5px;
                font-size: 1.2rem;
                border-radius: 4px;
            }
        }

        &__ti {
            @include sp {
                margin-top: 11px;
                font-size: 1.5rem;
            }
            @include tabpc {
                margin-top: 15px;
                font-size: 1.7rem;
            }
        }

        &__txt {
            @include sp {
                margin-top: 16px;
                font-size: 1.3rem;
                line-height: 1.4;
            }
            @include tabpc {
                margin-top: 18px;
                font-size: 1.5rem;
                line-height: 1.6;
            }
        }
        &__contact {
            text-align: center;
            @include sp {
                margin-top: 35px;
            }
            @include tabpc{
                margin-top: 60px;
            }
            .u-button03 {
                width: 100%;
                text-align: center;
                @include sp {
                    max-width: 228px;
                }
                @include tabpc {
                    max-width: 400px;
                }
            }
        }
    }
    //--------3階層
    .case-level3 {
        &__inner {
            @include sp {
                margin: 25px auto 25px;
            }
            @include tabpc {
                margin: 100px auto 50px;
            }
        }
        &__main {
            @include sp {
                padding: 0 25px;
            }
            @include tabpc {
                // min-width: 1100px;
                min-width: 1024px;
                max-width: 1280px;
                margin: 0 auto;
                padding: 0 20px;
            }
            .sec__h2 {
                width: 100%;
                @include sp {
                    font-size: 1.9rem;
                    padding: 0 0 6px 0;
                }
                @include tabpc {
                    font-size: 3.6rem;
                }
                &:before {
                    @include sp {
                        height: 2px;
                    }
                }
            }
        }
        &__read {
            @include sp {
                margin: 16px 0 0;
                font-size: 1.3rem;
                line-height: 1.5;
            }
            @include tabpc {
                margin: 25px 0 0;
                font-size: 1.7rem;
                line-height: 1.6;
            }
        }
        &__intro {
            @include sp {
                margin: 30px 0 0;
            }
            @include tabpc {
                margin: 70px 0 0;
            }
        }
        &__heading01 {
            font-weight: bold;
        }
        &__tag {
            display: flex;
            @include sp {
                flex-wrap: wrap;
                margin: 10px 0 0;
            }
            @include tabpc {
                margin: 20px 0 0;
            }
            .u-tag03 {
                @include sp {
                    margin-top: 5px;
                }
                &:not(:last-of-type) {
                    @include sp {
                        margin-right: 6px;
                    }
                    @include tabpc {
                        margin-right: 5px;
                    }
                }
            }
        }
        &__box {
            @include sp {
                margin: 25px 0 0;
            }
            @include tabpc {
                display: flex;
                margin: 40px 0 0;
            }
        }
        &__pic {
            @include tabpc {
                width: 32.3%;
                margin-right: 50px;
            }
            img {
                vertical-align: bottom;
            }
        }
        &__txt {
            @include sp {
                margin: 25px 0 0;
            }
            @include tabpc {
                width: 63%;
            }
            p {
                letter-spacing: 0;
                @include sp {
                    font-size: 1.3rem;
                    line-height: 1.5;
                    
                }
                @include tabpc {
                    font-size: 1.5rem;
                    line-height: 1.7;
                }
            }
        }
        &__group {
            @include sp {
                margin: 25px 0 0;
                
            }
            @include tabpc {
                margin: 30px 0 0;
            }
            .group01 {
                border: 1px solid $dark_gray;
                border-radius: 10px;
                @include sp {
                    
                }
                @include tabpc {
                    display: flex;
                    align-items: center;
                }
                &__inner {
                    display: flex;
                    align-items: center;
                    @include sp {
                        padding: 16px 15px;
                    }
                    @include tabpc {
                        padding: 23px 30px;
                        width: calc(100% / 3);
                    }
                    &:not(:last-of-type) {
                        @include sp {
                            border-bottom: 1px solid $dark_gray;
                        }
                        @include tabpc {
                            border-right: 1px solid $dark_gray;
                        }
                    }
                    &-w2 {
                        @include tabpc {
                            width: calc(100% / 2);
                        }
                    }
                }
                &__avatar {
                    @include sp {
                        width: 47px;
                        margin-right: 14px;
                    }
                    @include tabpc {
                        width: 71px;
                        margin-right: 19px;
                    }
                }
                &__txt {
                    @include sp {
                        font-size: 1.1rem;
                        line-height: 1.4;
                    }
                    @include tabpc {
                        font-size: 1.3rem;
                        line-height: 1.7;
                    }
                }
            }
        }
        &__nav {
            @include sp {
                margin: 35px 0 0;
            }
            @include tabpc {
                margin: 50px 0 0;
            }
        }
        &__tbl {
            @include sp {
                margin: 36px 0 0;
            }
            @include tabpc {
                margin: 50px 0 90px;
            }
            .tbl01 {
                &__inner {
                    @include sp {
                        padding-top: 40px;
                        margin-top: -40px;
                    }
                    @include tabpc {
                        display: flex;
                        justify-content: space-between;
                        padding: 70px 0 50px;
                        margin: -70px 0 50px;
                    }
                    &:not(:last-of-type) {
                        border-bottom: 1px solid $dark_gray;
                        @include sp {
                            padding-bottom: 23px;
                            margin-bottom: 23px;
                        }
                    }
                }
                &__ttl {
                    color: $green;
                    font-weight: bold;
                    @include sp {
                        font-size: 1.8rem;
                    }
                    @include tabpc {
                        max-width: 200px;
                        min-width: 200px;
                        font-size: 2.2rem;
                    }
                }
                &__details {
                    width: 100%;
                    @include sp {
                        margin: 25px 0 0;
                    }
                    @include tabpc {
                        max-width: 1040px; 
                    }
                }
                &__heading01 {
                    font-weight: bold;
                }
                &__img01 {
                    @include sp {
                        margin: 25px 0 0;
                    }
                    @include tabpc {
                        margin: 50px 0 0;
                    }
                    img {
                        vertical-align: bottom;
                    }
                    &-w50 {
                        @include tabpc {
                            margin: 50px auto 0;
                            width: 50%;
                        }
                    }
                }
                &__img02 {
                    @include sp {
                        position: relative;
                        margin: 25px 0 65px 0;
                    }
                    @include tabpc {
                        margin: 50px 0 0 30px;
                    }
                    .icon-glass {
                        @include sp {
                            position: absolute;
                            width: 26px;
                            right: 0;
                            bottom: -40px;
                        }
                    }
                    img {
                        vertical-align: bottom;
                    }
                }
                &__txt {
                    margin: 32px 0 0;
                    p {
                        letter-spacing: 0;
                        @include sp {
                            font-size: 1.3rem;
                            line-height: 1.5;
                        }
                        @include tabpc {
                            font-size: 1.5rem;
                            line-height: 1.7;
                        }
                    }
                }
                &__interview {
                    @include sp {
                        margin: 15px 0 0;
                    }
                    @include tabpc {
                        margin: 28px 0 0;
                    }
                    .interview {
                        &__inner {
                            display: flex;
                            justify-content: space-between;
                            @include tabpc {
                                margin-left: 20px;
                            }
                            &:not(:first-of-type) {
                                @include sp {
                                    margin: 22px 0 0 0;
                                }
                                @include tabpc {
                                    margin: 30px 0 0 20px;
                                }
                            }
                        }
                        &__avatar {
                            @include sp {
                                width: 47px;
                                margin-right: 10px;
                            }
                            @include tabpc {
                                width: 71px;
                                margin-right: 30px;
                            }
                        }
                        &__txt {
                            @include sp {
                                width: 80%;
                            }
                            @include tabpc {
                                max-width: 920px;
                                width: 100%;
                            }
                            p {
                                letter-spacing: 0;
                                @include sp {
                                    font-size: 1.3rem;
                                    line-height: 1.5;
                                }
                                @include tabpc {
                                    font-size: 1.5rem;
                                    line-height: 1.7;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__client {
            background-color: $gray;
            .client {
                &__inner {
                    @include sp {
                        margin: 47px auto 0;
                        padding: 25px;
                    }
                    @include tabpc {
                        // min-width: 1100px;
                        min-width: 1024px;
                        max-width: 1280px;
                        margin: 100px auto 0;
                        padding: 50px 20px;
                    }
                }
                &__wrap {
                    @include sp {
                        margin: 25px 0 0;
                    }
                    @include tabpc {
                        display: flex;
                        justify-content: space-between;
                        margin: 38px 0 0;
                    }
                }
                &__ttl {
                    color: $green;
                    font-weight: bold;
                    @include sp {
                        font-size: 1.4rem;
                    }
                    @include tabpc {
                        font-size: 1.7rem;
                    }
                }
                &__info {
                    @include tabpc {
                        width: 74.2%;
                        margin-right: 50px;
                    }
                }
                &__name {
                    font-weight: bold;
                    @include sp {
                        font-size: 1.5rem;
                    }
                    @include tabpc {
                        font-size: 2.2rem;
                    }
                }
                &__details {
                    @include sp {
                        margin: 22px 0 0;
                    }
                    @include tabpc {
                        margin: 25px 0 0;
                    }
                    p {
                        letter-spacing: 0;
                        @include sp {
                            font-size: 1.3rem;
                            line-height: 1.5;
                        }
                        @include tabpc {
                            font-size: 1.5rem;
                            line-height: 1.7;
                        }
                        &:not(:first-of-type) {
                            @include sp {
                                margin: 23px 0 0;
                            }
                            @include tabpc {
                                margin: 30px 0 0;
                            }
                        }
                    }
                }
                &__pic {
                    width: 100%;
                    @include sp {
                        margin: 22px 0 0;
                    }
                    @include tabpc {
                        max-width: 267px;
                    }
                }
            }
        }
        &__contact {
            @include sp {
                margin: 35px 0 0;
            }
            @include tabpc {
                margin: 50px 0 0;
            }
            .contact {
                &__inner {
                    text-align: center;
                    @include sp {
                        margin: 0 25px;
                        padding-bottom: 50px;
                        border-bottom: 1px solid $dark_gray;
                    }
                }
                &__btn {
                    width: 100%;
                    @include tabpc {
                        max-width: 400px;
                    }
                }
            }
        }
        .service__case {
            @include tabpc {
                margin-top: 130px;
            }
            .u-heading02 {
                font-size: 2.2rem;
                text-align: left;
            }
            .case01__row{
                justify-content: center;
                .u-card02{
                    margin: 0 14px;
                }
            }
        }
    }
}

//-------common
.u-select01 {
    display: block;
    position: relative;
    width: 100%;
    height: 45px;
    border: 1px solid $dark_gray;
    color: $default;
    font-size: 1.3rem;
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 12px);
        right: 17px;
        width: 15px;
        height: 15px;
        border-right: 1px solid $default;
        border-bottom: 1px solid $default;
        transform: rotate(45deg);
    }
    select {
        padding: 0 14px;
        width: 100%;
        height: 100%;
    }
}
.u-tag03 {
    display: inline-block;
    border: 1px solid $green;
    color: $green;
    text-align: center;
        @include sp {
            padding: 0px 8px;
            font-size: 1.1rem;
            border-radius: 3px;
        }
        @include tabpc {
            padding: 5px 10px;
            font-size: 1.2rem;
            border-radius: 4px;
        }
}
.u-emp01 {
    color: $green;
    font-weight: bold;
}
.u-button04 {
    display: inline-block;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    padding: 16px 10px;
    background: $yellow;
    box-shadow: 0 5px 0 0 $yellow_shadow;
    border-radius: 10px;
    >span {
        @include position();
        &:before,
        &:after {
            content: "";
            @include position(absolute,19%,-14px,n,n);
            width: 2px;
            height: 14px;
            background: $default;
        }
        &:before {
            margin: 4px 0 0 0;
            transform: rotate(50deg);
        }
        &:after {
            margin: -4px 0 0 0;
            transform: rotate(-50deg);
        }    
    }
    &:hover {
        color: $default;
        opacity: .7;
    }
}
.u-ta-r {
    text-align: right;
}
.nav01 {
    background-color: $green;
    @include sp {
        padding: 10px 0;
    }
    @include tabpc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }
    &__item {
        @include tabpc {
            width: calc(100% / 4);
        }
        &-w3 {
            @include tabpc {
                width: calc(100% / 3);
            }
        }
        &:not(:last-of-type) {
            @include tabpc {
                border-right: 1px solid $white;
            }
        }
    }
    &__link {
        display: inline-block;
        width: 100%;
        height: 100%;
        color: $white;
        @include sp {
            padding: 10px 32px;
        }
        @include tabpc {
            padding: 15px 0;
            font-size: 1.5rem;
            text-align: center;
        }
        &:before {
            content: '';
            display: inline-block;
            position: relative;
            border-style: solid;
            border-color: $white transparent transparent transparent;
            @include sp {
                left: -14px;
                width: 8px;
                height: 8px;
                border-width: 8px 5px 0 5px;
            }
            @include tabpc {
                left: -6px;
                width: 10px;
                height: 10px;
                border-width: 10px 5px 0 5px;
            }
        }
        &:hover {
            color: $white;
            opacity: .7;
        }
    }
}